import Vue from 'vue';
import store from '@/store';
import Router from 'vue-router';
import Index from '@/views/Index.vue';
import Login from '@/views/Login.vue';
import Base from '@/layouts/Base.vue';
import Reports from '@/views/Reports.vue';
import BaseOrgan from '@/layouts/BaseOrgan.vue';
import NewOrgan from '@/views/organs/NewOrgan.vue';
import EditOrgan from '@/views/organs/EditOrgan.vue';
import EditPolicyExemptions from '@/views/organs/EditPolicyExemptions.vue';
import EditDeceasedDonorAllocations from '@/views/deceasedDonors/EditDeceasedDonorAllocations.vue';
import BaseRecipient from '@/layouts/BaseRecipient.vue';
import RecipientHla from '@/views/hla/RecipientHla.vue';
import NewRecipient from '@/views/recipients/NewRecipient.vue';
import BaseDeceasedDonor from '@/layouts/BaseDeceasedDonor.vue';
import EditRecipient from '@/views/recipients/EditRecipient.vue';
import WaitlistSummary from '@/views/waitlist/WaitlistSummary.vue';
import ListRecipients from '@/views/recipients/ListRecipients.vue';
import NewDeceasedDonor from '@/views/deceasedDonors/NewDeceasedDonor.vue';
import EditDeceasedDonor from '@/views/deceasedDonors/EditDeceasedDonor.vue';
import ListDeceasedDonors from '@/views/deceasedDonors/ListDeceasedDonors.vue';
import ListDeceasedDonorsActive from '@/views/deceasedDonors/ListDeceasedDonorsActive.vue';
import NewLivingDonor from '@/views/livingDonors/NewLivingDonor.vue';
import BaseLivingDonor from '@/layouts/BaseLivingDonor.vue';
import EditLivingDonor from '@/views/livingDonors/EditLivingDonor.vue';
import EditLivingDonorAllocations from '@/views/livingDonors/EditLivingDonorAllocations.vue';
import ListLivingDonors from '@/views/livingDonors/ListLivingDonors.vue';
import Administration from '@/views/Administration.vue';
import CtrIntegration from '@/views/administration/CtrIntegration.vue';
import Accounts from '@/views/administration/Accounts.vue';
import ProfilePage from '@/views/administration/ProfilePage.vue';
import EditRecipientOop from '@/views/recipientsOop/EditRecipientOop.vue';
import EditOrganOop from '@/views/recipientsOop/EditOrganOop.vue';
import SearchIndex from "@/views/search/SearchIndex.vue";
import Test from "@/views/test/Test.vue";
import { isMasked } from '@/utils';
import i18n from '@/i18n';
import FusionRequest from '@/views/FusionRequest.vue';
import AccessToken from '@/views/AccessToken.vue';

// Set CSS scroll behaviour for the html tag to smooth
document.documentElement.style.scrollBehavior = 'smooth';

// Configure Bootstrap scroll spy functionality to show scroll position on the sidebar
document.body.setAttribute('data-spy', 'scroll');
document.body.setAttribute('data-target', '.page-nav');
document.body.setAttribute('data-offset', '150');
document.body.setAttribute('data-n-head','data-spy,data-target,data-offset');

/*
  Configure which 'option' values are permitted for deceased-donor-organ-option (allocation) route. This determines
  routing behaviour only, whereas consequences of these options are determined within the relevant vue component.
  E.g. Kidney allocations must be 'local' or 'provincial', which affects the allocation POST request payload generated
  in the 'AllocationControls' component.
*/
const ORGAN_ALLOCATION_OPTIONS: { [key: string]: string[] } = {
  // Kidney
  '3': ['local', 'provincial', 'double'],
};

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  // Define custom scroll behaviour for Vue Router
  scrollBehavior(to, from, savedPosition) {
    let result: any = null;
    if (to.hash) {
      // Resolve routes with hashes by scrolling to the selected ID with offset
      result = new Promise((resolve, reject) => {
        setTimeout(() => {
          const element = document.getElementById(to.hash.replace('#', ''));
          if (element) {
            const x = element.getBoundingClientRect().top + window.pageXOffset;
            const y = window.innerWidth < 767 ? 25 : 150 ;
            resolve({
              selector: to.hash,
              offset: { x: 325, y: y },
            });
          }
        }, 150);
      });
    } else {
      // Otherwise scroll to the top of the page
      result = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            x: 0, y: 0
          });
        }, 50);
      });
    }
    return result;
  },
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/recipients',
      component: Base,
      children: [
        {
          path: '',
          component: ListRecipients,
          name: 'list-recipients'
        },
        {
          path: 'new',
          component: NewRecipient,
          name: 'new-recipient',
          beforeEnter: (to, from, next) => {
            store.dispatch('users/loadUser');
            store.dispatch('validations/reset'),
            store.commit('setPageTitle', 'New Recipient');
            store.commit('labs/clearVirologies');
            store.dispatch('users/setRoute', 'recipients');
            next();
          }
        },
        {
          path: ':id',
          component: BaseRecipient,
          beforeEnter: (to, from, next) => {
            store.dispatch('users/loadUser');
            store.dispatch('validations/reset'),
            store.commit('setPageTitle', 'Loading...');
            store.commit('recipients/clearRecipient');
            store.commit('recipients/clearRecipientDeath');
            store.dispatch('recipients/get', to.params.id);
            store.dispatch('users/setRoute', 'recipients');
            next();
          },
          children: [
            {
              path: 'new/organ/:organ_code',
              component: NewOrgan,
              name: 'new-organ',
              beforeEnter: (to, from, next) => {
                store.dispatch('users/loadUser');
                store.dispatch('validations/reset'),
                store.commit('setPageTitle', 'Loading...');
                store.commit('journeyState/clearJourney');
                store.commit('journeyState/clearWaitlistDecisions');
                store.commit('journeyState/clearJourneyDurations');
                store.dispatch('users/setRoute', 'recipients/organ');
                next();
              }
            },
            {
              path: 'organs',
              component: BaseOrgan,
              beforeEnter: (to, from, next) => {
                store.dispatch('users/loadUser');
                store.dispatch('validations/reset'),
                store.commit('journeyState/clearJourney');
                store.commit('journeyState/clearWaitlistDecisions');
                store.commit('journeyState/clearJourneyDurations');
                store.dispatch('recipients/get', to.params.id).then(() => {
                  store.dispatch('journeyState/getJourney', to.params.organ_id);
                });
                store.dispatch('users/setRoute', 'recipients/organ');
                next();
              },
              children: [
                {
                  path: 'edit/:organ_id',
                  component: EditOrgan,
                  name: 'edit-organ',
                  beforeEnter: (to, from, next) => {
                    // store.commit('recipients/clearRecipientDiagnosticsHla');
                    next();
                  },
                },
                {
                  path: 'edit/:organ_id/policy_exemptions',
                  component: EditPolicyExemptions,
                  name: 'edit-organ-policy_exemptions',
                  beforeEnter: (to, from, next) => {
                    // store.commit('recipients/clearRecipientDiagnosticsHla');
                    next();
                  },
                }
              ]
            },
            {
              path: 'hla',
              component: RecipientHla,
              name: 'recipient-hla',
              beforeEnter: (to, from, next) => {
                store.commit('recipients/clearRecipientDiagnosticsHla');
                next();
              },
            },
            // if nothing matches above show the edit page
            {
              path: '',
              component: EditRecipient,
              name: 'edit-recipient',
              beforeEnter: (to, from, next) => {
                store.dispatch('users/loadUser');
                store.dispatch('validations/reset'),
                store.dispatch('users/setRoute', 'recipients');
                next();
              }
            }
          ]
        }
      ]
    },
    {
      path: '/oop_recipients',
      component: Base,
      children: [
        {
          path: '/recipients/#oop-recipients',
          component: ListRecipients,
          name: 'list-recipients-oop'
        },
        {
          path: ':id',
          component: BaseRecipient,
          beforeEnter: (to, from, next) => {
            store.dispatch('users/loadUser');
            store.dispatch('validations/reset'),
            store.commit('setPageTitle', 'Loading...');
            store.commit('recipients/clearRecipient');
            store.commit('recipients/clearRecipientDeath');
            store.dispatch('recipients/getOop', to.params.id);
            store.dispatch('users/setRoute', 'oop_recipients');
            next();
          },
          children: [
            {
              path: 'organs',
              component: BaseOrgan,
              beforeEnter: (to, from, next) => {
                store.dispatch('users/loadUser');
                store.dispatch('validations/reset'),
                store.commit('journeyState/clearJourney');
                store.commit('journeyState/clearWaitlistDecisions');
                store.commit('journeyState/clearJourneyDurations');
                store.dispatch('recipients/getOop', to.params.id).then(() => {
                  store.dispatch('journeyState/getJourney', to.params.organ_id);
                });
                store.dispatch('users/setRoute', 'oop_recipients/organ');
                next();
              },
              children: [
                {
                  path: 'edit/:organ_id',
                  component: EditOrganOop,
                  name: 'edit-organ-oop',
                }
              ]
            },
            // if nothing matches above show the edit page
            {
              path: '',
              component: EditRecipientOop,
              name: 'edit-recipient-oop',
              beforeEnter: (to, from, next) => {
                store.dispatch('users/loadUser');
                store.dispatch('validations/reset'),
                store.dispatch('users/setRoute', 'oop_recipients');
                next();
              }
            }
          ]
        }
      ]
    },
    {
      path: '/donors',
      component: Base,
      children: [
        {
          path: '',
          component: ListDeceasedDonors,
          name: 'list-deceased-donors'
        },
        {
          path: 'active',
          component: ListDeceasedDonorsActive,
          name: 'list-deceased-donors-active'
        },
        {
          path: 'new',
          component: NewDeceasedDonor,
          name: 'new-deceased-donor',
          beforeEnter: (to, from, next) => {
            store.dispatch('users/loadUser');
            store.dispatch('validations/reset'),
            store.commit('setPageTitle', 'New Donor');
            store.dispatch('users/setRoute', 'donor');
            next();
          }
        },
        {
          path: ':id',
          component: BaseDeceasedDonor,
          beforeEnter: (to, from, next) => {
            store.dispatch('users/loadUser');
            store.dispatch('validations/reset'),
            store.commit('setPageTitle', 'Loading...');
            store.commit('labs/clearCrossmatchLab');
            store.commit('labs/clearCrossmatchSample');
            store.dispatch('users/setRoute', 'donor');
            next();
          },
          children: [
            {
              path: 'organ/:organ_code',
              name: 'deceased-donor-organ',
              component: EditDeceasedDonorAllocations,
              beforeEnter: (to, from, next) => {
                store.dispatch('users/loadUser');
                store.dispatch('validations/reset'),
                // load root donor record
                store.commit('deceasedDonors/clearDeceasedDonor');
                store.dispatch('deceasedDonors/get', to.params.id);
                store.dispatch('users/setRoute', 'donor/organ');
                // get exclusion filters
                store.dispatch('allocations/getExclusionRules', { clientId: to.params.id, organCode: to.params.organ_code });
                next();
              },
              children: [
                {
                  path: ':option',
                  name: 'deceased-donor-organ-option',
                  component: EditDeceasedDonorAllocations,
                  beforeEnter: (to, from, next) => {
                    // Get 'option' route parameter for Organ type e.g. 'local' or 'provincial' for Kidney
                    const option: string = to.params.option;
                    // Reject route if 'option' route parameter not expected for Organ type
                    const organCode: string = to.params.organ_code;
                    const organOptions: string[] = ORGAN_ALLOCATION_OPTIONS[organCode] || [];
                    if (!organOptions.includes(option)) {
                      // Redirect to Donor Profile page if invalid 'option'
                      console.warn('Invalid organ allocation option', { option, organCode });
                      next({ name: 'edit-deceased-donor', params: to.params });
                    } else {
                      // Proceed to Organ route only if valid 'option' route parameter
                      next();
                    }
                  },
                }
              ],
            },
            {
              path: '',
              component: EditDeceasedDonor,
              name: 'edit-deceased-donor',
              beforeEnter: (to, from, next) => {
                store.dispatch('users/loadUser');
                store.dispatch('validations/reset'),
                store.dispatch('users/setRoute', 'donor');
                // load root donor record
                store.commit('deceasedDonors/clearDeceasedDonor');
                store.dispatch('deceasedDonors/get', to.params.id);
                next();
              },
            }
          ]
        }
      ]
    },
    {
      path: '/living-donors',
      component: Base,
      children: [
        {
          path: '',
          component: ListLivingDonors,
          name: 'list-living-donors'
        },
        {
          path: 'new',
          component: NewLivingDonor,
          name: 'new-living-donor',
          beforeEnter: (to, from, next) => {
            store.dispatch('users/loadUser');
            store.dispatch('validations/reset'),
            store.commit('setPageTitle', 'New Living Donor');
            store.dispatch('users/setRoute', 'livingDonor');
            next();
          }
        },
        {
          path: ':id',
          component: BaseLivingDonor,
          beforeEnter: (to, from, next) => {
            store.dispatch('users/loadUser');
            store.dispatch('validations/reset'),
            store.commit('setPageTitle', 'Loading...');
            store.commit('livingDonors/clearLivingDonor');
            store.dispatch('livingDonors/get', to.params.id);
            store.dispatch('users/setRoute', 'livingDonor');
            next();
          },
          children: [
            {
              path: 'organ/:organ_code',
              name: 'living-donor-organ',
              component: EditLivingDonorAllocations,
              beforeEnter: (to, from, next) => {
                store.dispatch('users/loadUser');
                store.dispatch('validations/reset'),
                // load root donor record
                store.commit('livingDonors/clearLivingDonor');
                store.dispatch('livingDonors/get', to.params.id);
                store.dispatch('users/setRoute', 'livingDonor/organ');
                // get exclusion filters
                store.dispatch('livingAllocations/getExclusionRules', { clientId: to.params.id, organCode: to.params.organ_code });
                next();
              },
              children: [
                {
                  path: ':option',
                  name: 'living-donor-organ-option',
                  component: EditLivingDonorAllocations,
                  beforeEnter: (to, from, next) => {
                    // Get 'option' route parameter for Organ type e.g. 'local' or 'provincial' for Kidney
                    const option: string = to.params.option;
                    // // Reject route if 'option' route parameter not expected for Organ type
                    const organCode: string = to.params.organ_code;
                    const organOptions: string[] = ORGAN_ALLOCATION_OPTIONS[organCode] || [];
                    if (!organOptions.includes(option)) {
                      // Redirect to Donor Profile page if invalid 'option'
                      console.warn('Invalid organ allocation option', { option, organCode });
                      next({ name: 'edit-living-donor', params: to.params });
                    } else {
                      // Proceed to Organ route only if valid 'option' route parameter
                      next();
                    }
                  },
                }
              ],
            },
            {
              path: '',
              component: EditLivingDonor,
              name: 'edit-living-donor',
              beforeEnter: (to, from, next) => {
                store.dispatch('users/loadUser');
                store.dispatch('validations/reset'),
                store.dispatch('users/setRoute', 'livingDonor');
                next();
              },
            }
          ]
        }
      ]
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      beforeEnter: (to, from, next) => {
        store.commit('setPageTitle', 'Reports');
        next();
      }
    },
    {
      path: '/administration',
      component: Base,
      children: [
        {
          path: '',
          name: 'administration',
          component: Administration,
          beforeEnter: (to, from, next) => {
            store.commit('setPageTitle', 'Administration');
            next();
          },
        },
        {
          path: 'users',
          name: 'Users',
          component: Accounts,
          beforeEnter: (to, from, next) => {
            store.commit('setPageTitle', 'Manage Users');
            next();
          },
        },
        {
          path: 'profile',
          name: 'profilePage',
          component: ProfilePage,
          beforeEnter: (to, from, next) => {
            store.commit('setPageTitle', 'Profile Page');
            next();
          },
        },
        {
          path: 'ctr',
          name: 'ctrIntegration',
          component: CtrIntegration,
          beforeEnter: (to, from, next) => {
            store.commit('setPageTitle', 'CTR Integration');
            next();
          },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/access_token/fusionauth',
      name: 'fusionRequest',
      component: FusionRequest
    },
    {
      path: '/access_token',
      name: 'accessToken',
      component: AccessToken
    },
    {
      path: '/waitlist',
      name: 'waitlist',
      component: WaitlistSummary,
      beforeEnter: (to, from, next) => {
        store.commit('setPageTitle', 'Waitlist');
        next();
      },
    },
    {
      name: 'global-search',
      path: '/search',
      component: SearchIndex
    },
    {
      path: '/test',
      name: 'test',
      component: Test
    }
  ]
});

// Default redirection guard for all routes.
router.beforeEach((to, from, next) => {

  // detect masked param with alert
  if (to.params) {
    const params = to.params;
    let mask_found = false;
    Object.keys(params).forEach(function (key) {
      if (isMasked(params[key])) mask_found = true;
    });
    if (mask_found) {
      alert(i18n.tc('permission-error'));
      // if possible return to the previous page
      if (from.fullPath) window.location.href = from.fullPath;
      next(false);
    }
  }

  store.dispatch('users/readAccessToken').then(() => {
    const isAuthenticated = store.getters['users/hasAccessToken'];
    if (to.name !== 'login' && to.name !== 'accessToken' && to.name !== 'fusionRequest' && !isAuthenticated) {
      // check for url before redirecting to login, store it to redirect after login
      const _to = to.fullPath || null;
      if (_to) {
        // store url in local storage and continue to login
        localStorage.setItem('after_login_url', _to);
        next({ name: 'login' });
      }
    } else {
      next();
    }
  }).catch(() => {
    next(false);
  });
});

router.afterEach((to, from) => {
  // const _to = to.hash || null;
  // let timeout = 0;
  // const navigationType = window.performance ? window.performance.navigation.type : null;
  // const historyLength = window.history.length || 0;

  // // if page opened in new tab
  // if (historyLength === 1 && navigationType === 0) {
  //   timeout = 1000;
  // // if page reloaded & has hash, scroll to with delay
  // } else if (navigationType === 1 && _to) {
  //   timeout = 1500;
  // // otherwise
  // } else {
  //   timeout = 150;
  // }


  // if (_to) {    // Resolve routes with hashes by scrolling to the selected ID with offset
  //   setTimeout(() => {
  //     const element = document.getElementById(_to.replace('#', ''));
  //     if (element) {
  //       const y = element.getBoundingClientRect().top + window.pageYOffset - 102;
  //       window.scrollTo({top: y, behavior: 'smooth'});
  //     }
  //   }, timeout);
  // }


  // Double check on troubled links
//   if (_to === "#virology" || _to === "#donor-documents" || _to === "#hla-typing") {
//       setTimeout(() => {
//         const element = document.getElementById(_to.replace('#', ''));
//         if (element) {
//           const y = element.getBoundingClientRect().top + window.pageYOffset - 102;
//           window.scrollTo({top: y, behavior: 'smooth'});
//         }
//       }, 1500);
//   }
});
