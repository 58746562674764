import { render, staticRenderFns } from "./CardSection.vue?vue&type=template&id=4a21f5ca&scoped=true&"
import script from "./CardSection.vue?vue&type=script&lang=ts&"
export * from "./CardSection.vue?vue&type=script&lang=ts&"
import style0 from "./CardSection.vue?vue&type=style&index=0&id=4a21f5ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a21f5ca",
  null
  
)

/* custom blocks */
import block0 from "@/components/shared/_locales/CardSection.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fshared%2FCardSection.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports