

import { State, Getter } from 'vuex-class';
import { SaveResult, SaveProvider } from '@/types';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import ClinicalTransaction from '@/components/recipients/ClinicalTransaction.vue';
import SmallBowelSpecificDetails from '@/components/organs/bowel/SmallBowelSpecificDetails.vue';
import DonorAcceptability from '@/components/organs/shared/DonorAcceptability.vue';
import OrganDetailsSection from '@/components/organs/shared/_OrganDetailsSection.vue';
import ReferralSection from '@/components/organs/shared/_ReferralSection.vue';
import ConsultationSection from '@/components/organs/shared/_ConsultationSection.vue';
import MedicalAssessmentSection from '@/components/organs/shared/_MedicalAssessmentSection.vue';
import WaitlistSection from '@/components/organs/shared/_WaitlistSection.vue';
import TransplantDetailsSection from '@/components/organs/shared/_TransplantDetailsSection.vue';
import PostTransplantSection from '@/components/organs/shared/_PostTransplantSection.vue';
import { SystemModules } from '@/store/features/types';

@Component({
  components: {
    ClinicalTransaction,
    OrganDetailsSection,
    ReferralSection,
    ConsultationSection,
    MedicalAssessmentSection,
    WaitlistSection,
    TransplantDetailsSection,
    PostTransplantSection,
    DonorAcceptability,
    SmallBowelSpecificDetails
  }
})
export default class SmallBowel extends Vue {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney|undefined;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;

  // Is the Consultation Section module enabled?
  get isConsultationSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CONSULTATION_SECTION);
  }

  // Is the Medical Assessment Section module enabled?
  get isMedicalAssessmentSectionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.MEDICAL_ASSESSMENT_SECTION);
  }

  // Is the Clinical Transaction module enabled?
  get isClinicalTransactionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CLINICAL_TRANSACTIONS);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};

    // Organ Details Section
    const organDetailsSection = this.$refs.organDetailsSection as OrganDetailsSection;
    if (organDetailsSection) {
      Object.assign(result, { ...organDetailsSection.idLookup });
    }

    // Referral Section
    const referralSection = this.$refs.referralSection as ReferralSection;
    if (referralSection) {
      Object.assign(result, { ...referralSection.idLookup() });
    }

    // Consultation Section
    const consultationSection = this.$refs.consultationSection as ConsultationSection;
    if (consultationSection) {
      Object.assign(result, { ...consultationSection.idLookup() });
    }

    // Medical Assessment Section
    const medicalAssessmentSection = this.$refs.medicalAssessmentSection as MedicalAssessmentSection;
    if (medicalAssessmentSection) {
      Object.assign(result, { ...medicalAssessmentSection.idLookup() });
    }

    // Organ Specific Details Section
    const organSpecificDetailsSection = this.$refs.organSpecificDetailsSection as SmallBowelSpecificDetails;
    if (organSpecificDetailsSection) {
      Object.assign(result, { ...organSpecificDetailsSection.idLookup() });
    }

    // Donor Acceptability Section
    const donorAcceptabilitySection = this.$refs.donorAcceptabilitySection as DonorAcceptability;
    if (donorAcceptabilitySection) {
      Object.assign(result, { ...donorAcceptabilitySection.idLookup });
    }

    // Waitlist Section
    const waitlistSection = this.$refs.waitlistSection as WaitlistSection;
    if (waitlistSection) {
      Object.assign(result, { ...waitlistSection.idLookup() });
    }

    // Transplant Details Section
    const transplantDetailsSection = this.$refs.transplantDetailsSection as TransplantDetailsSection;
    if (transplantDetailsSection) {
      Object.assign(result, { ...transplantDetailsSection.idLookup() });
    }

    // Post Transplant Section
    const postTransplantSection = this.$refs.postTransplantSection as PostTransplantSection;
    if (postTransplantSection) {
      Object.assign(result, { ...postTransplantSection.idLookup() });
    }

    return result;
  }

  // PRIVATE

  // Loading processes unique to this component
  private mounted(): void {
    if (!this.newJourney) {
      // Fetch all recipient decision events
      this.$store.dispatch('recipients/loadDecisionEvents', this.recipientId);
    }
    // Collect all sections we're loading
    const sectionsToLoad: string[] = Array.from(Object.keys(this.$refs));
    // Emit to parent all sections we're loading
    this.$emit('sectionsToLoad', sectionsToLoad);
  }
  
  // Emit a loaded event with the component name
  private loaded(ref: string): void {
    this.$emit('loaded', ref);
  }

  // Initialize here
  private initializeAllSections(): void {
    // Organ Details
    (this.$refs.organDetailsSection as OrganDetailsSection).initializeForm();

    // Referral Section
    const referralSection = this.$refs.referralSection as ReferralSection;
    if (referralSection) { referralSection.initializeForm(); }

    // Consultation Section
    const consultationSection = this.$refs.consultationSection as ConsultationSection;
    if (consultationSection) { consultationSection.initializeForm(); }

    // Medical Assessment Section
    const medicalAssessmentSection = this.$refs.medicalAssessmentSection as MedicalAssessmentSection;
    if (medicalAssessmentSection) { medicalAssessmentSection.initializeForm(); }

    // Organ Specific Details
    (this.$refs.organSpecificDetailsSection as SmallBowelSpecificDetails).initializeForm();

    // Donor Acceptability Section
    (this.$refs.donorAcceptabilitySection as DonorAcceptability).initializeForm();

    // Waitlist Section
    (this.$refs.waitlistSection as WaitlistSection).initializeForm();

    // Transplant Details Section
    (this.$refs.transplantDetailsSection as TransplantDetailsSection).initializeForm();

    // Post Transplant Section
    (this.$refs.postTransplantSection as PostTransplantSection).initializeForm();
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit('saving', formReference);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private clear() {
    this.$emit('clear');
  }

  // initialize the donor acceptability criteria section
  private reloadDonorAcceptabilityCriteria() {
    const donorAcceptabilitySection = this.$refs.donorAcceptabilitySection as unknown as DonorAcceptability;
    if (donorAcceptabilitySection) donorAcceptabilitySection.initializeForm();
  }

  // initialize the organ Specific Details Section
  private reloadOrganSpecificDetails() {
    const organSpecificDetailsSection = this.$refs.organSpecificDetailsSection as SmallBowelSpecificDetails;
    if (organSpecificDetailsSection) organSpecificDetailsSection.initializeForm();
  }

  // Reload the Referral Section (Details and Decision) if related form has saved
  private reloadReferralSection(): void {
    const referralSection = this.$refs.referralSection as ReferralSection;
    if (referralSection) referralSection.initializeForm();
  }
}
