
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State, Getter } from "vuex-class";
import { GenericCodeValue } from "@/store/types";
import { TableConfig } from "@/types";
import { ObjectId } from "@/store/types";
import { Organ, OrganWaitlistMedicalStatus, OrganDiseaseCode, OrganSpecification } from "@/store/lookups/types";
import { Recipient } from "@/store/recipients/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import SubSection from "@/components/shared/SubSection.vue";
import CardSection from "@/components/shared/CardSection.vue";
import NewOrganLink from "@/components/shared/NewOrganLink.vue";
import { AssessmentCancellationReasons, AssessmentState, JourneyStage, JourneyStatus, RecipientJourney, RecipientStageAttributes, RecipientWaitlistAttributes, RecipientWaitlistFactors } from "@/store/recipientJourney/types";
import { Coordinator } from "@/store/coordinators/types";
import PreviousTransplants from '@/components/recipients/PreviousTransplants.vue';
import { PreviousTransplantForm } from '@/components/recipients/PreviousTransplants.vue';
import { Hospital } from '@/store/hospitals/types';
import { titleCase } from "@/utils";
import { SystemModules } from '@/store/features/types';

export interface OrganReferralForm {
  previousTransplant: PreviousTransplantForm;
}

interface OrganReferralRow {
  _id?: ObjectId;
  referredOrgan?: string;
  referralDate?: string;
  clusteredWith?: string;
  journeyStatus?: string;
  journeyPhase?: string;
  listingDate?: string;
  transplantDate?: string;
  transplantProgram?: string;
}

@Component({
  components: {
    SubSection,
    CardSection,
    NewOrganLink,
    PreviousTransplants,
  }
})
export default class OrganReferral extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.hospitals.transplantProgram) private transplantProgram!: Hospital[];
  @State(state => state.pageState.currentPage.organReferrals) editState!: OrganReferralForm;

  @Getter("diagnosisValue", { namespace: "lookups" }) diagnosisValue!: (organCode: number | undefined, diagnosisCode: number | null | undefined) => string | undefined;
  @Getter('getJourneyStatusDisplayValue', { namespace: 'recipients' }) journeyStatusDisplayValue!: (journey: RecipientJourney) => string|undefined;
  @Getter('getJourneyStageDisplayValue', { namespace: 'recipients' }) journeyStageDisplayValue!: (journey: RecipientJourney) => string|undefined;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private isFinishedLoadingEvents = false;
  private isFinishedLoadingSubsections = false;
  private previousTranspantProgramsIds: any = [];

  private lookupsToLoad = [
    'oop_transplant_organ_codes',
  ];

  public selectOrganReferral(event: any) {
    if (this.recipient.client_id !== undefined) {
      const clientId: string = this.recipient.client_id.toString();
      this.$router.push({
        name: "edit-organ",
        params: {
          id: clientId,
          organ_id: event.row._id.$oid
        }
      });
    } else {
      console.warn(this.$t('missing_recipient_clientid').toString());
      this.$router.push({ name: "list-recipients" });
    }
  }

   // Called as sub-sections finish loading
  private loadedSubsection(sectionName: string): void {
    this.isFinishedLoadingSubsections = true;
    this.checkIfLoadingComplete();
  }

   // Section is finished loading when dependencies are loaded
  private checkIfLoadingComplete(): void {
    if ((this.isFinishedLoadingSubsections || this.newRecipient)) {
      this.initializeForm();
    }
  }

   // Copy relevant data from recipient into the pageState
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'organReferrals',
      value: this.buildOrganReferralForm(this.recipient)
    });
  }

   // Extract relevant parts from Recipient to match the form layout
  public buildOrganReferralForm(recipient: Recipient): OrganReferralForm{
    // Initialize form states for sub-sections
    const previousTransplantsForm = this.$refs.previousTransplants as PreviousTransplants;
    const previousTransplant = previousTransplantsForm ? previousTransplantsForm.buildPreviousTransplantForm() : {};
    const result: OrganReferralForm = {
      previousTransplant,
    };
    return result;
  }

  private clear() {
    this.$emit('clear');
  }

  /**
   * Start loading processes unique to this component
   */
  private mounted(): void {
    this.getTransplantPrograms();
  }

  public getTransplantPrograms(): void {
    const recipientJourneys = Array.isArray(this.recipient.journeys)
      ? this.recipient.journeys
      : [];

    // Remove Out of Province Journeys from Recipient Journeys list
    const inProvinceJourneys = recipientJourneys.filter((journey: RecipientJourney) => {
      return !journey.oop_journey;
    });
    const transpantProgramsIds = inProvinceJourneys.map((item: any) => {
      const transplantProgramId = item.transplant_program.transplant_hospital_id;
      return transplantProgramId ? transplantProgramId.$oid :null;
    });
    transpantProgramsIds.filter(Boolean);

    // avoid repeating get request on transplant program ids
    if (transpantProgramsIds.join(' ') != this.previousTranspantProgramsIds.join(' ')) {
      this.$store.dispatch("hospitals/get", transpantProgramsIds).then(() => {
        this.previousTranspantProgramsIds = transpantProgramsIds;
      });
    }
  }

  /**
   * Checks if loading has completed when the lookups have finished loading
   *
   * Called after the Card Section has finished loading all relevant lookups.
   *
   * @listens journey#loaded
   * @emits loaded
   */
  private loaded(): void {
    this.$emit('loaded', 'organReferrals');
  }

  /**
   * Gets table configuration for the Organ Referrals table
   *
   * @returns {TableConfig} table configuration for Organ Referrals
   */
  get organReferralsTableConfig(): TableConfig {
    return {
      data: this.organReferralRows,
      columns: [
        { label: this.$t('referred_organ').toString(), field: "referredOrgan", width: "12.5%" },
        { label: this.$t('clustered_with').toString(), field: "clusteredWith", width: "12.5%" },
        {
          label: this.$t('phase').toString(),
          field: "journeyPhase",
          width: "12.5%"
        },
        {
          label: this.$t('status').toString(),
          field: "journeyStatus",
          width: "12.5%"
        },
        {
          label: this.$t('referral_date').toString(),
          field: "referralDate",
          width: "12.5%"
        },
        {
          label: this.$t('listing_date').toString(),
          field: "listingDate",
          width: "12.5%"
        },
        {
          label: this.$t('transplant_date').toString(),
          field: "transplantDate",
          width: "12.5%"
        },
        {
          label: this.$t('transplant_program').toString(),
          field: "transplantProgram",
          width: "25.5%"
        }
      ],
      empty: this.$t('use_button').toString(),
      createButton: false
    };
  }

  /**
   * Gets an array of rows for the Organ Referrals table based on Recipient Journeys
   *
   * @returns {OrganReferralRow[]} table data for organ referrals
   */
  get organReferralRows(): OrganReferralRow[] {
    if (!this.recipient || !this.recipient.journeys) {
      return [];
    }

    const journeys = this.recipient.journeys;
    // Remove Out of Province Journeys from Recipient Journeys list
    const inProvinceJourneys = journeys.filter((journey: RecipientJourney) => {
      return !journey.oop_journey;
    });
    const rows = inProvinceJourneys.map((journey: RecipientJourney) => {
      // Fetch lookup values for Referred Organ and Transplant Type based on journey Organ Code
      let referredOrgan: string | undefined = undefined;
      const organCode = journey.organ_code;
      if (organCode && this.organLookup) {
        const match: Organ | undefined = this.organLookup.find((organ: Organ) => {
            return organ.code == organCode;
        });
        if (match) {
          referredOrgan = organCode ? this.$t(match.value).toString() : undefined;
        }
      }
      const stage_attributes = journey.stage_attributes;
      const referral = stage_attributes?.referral;
      const waitlistFactors = stage_attributes?.waitlist?.factors;
      const transplantFactors = stage_attributes?.transplant?.factors;

      const transplant_Program = journey.transplant_program;
      const hospitalId = transplant_Program?.transplant_hospital_id?.$oid;

      const referralDate = referral?.received_date ? this.parseDisplayDateUi(referral?.received_date) : undefined;
      const journeyStatus = this.statusDisplayValue(journey);
      const journeyPhase = this.stageDisplayValue(journey);
      const listingDate = waitlistFactors?.listing_date ? this.parseDisplayDateUi(waitlistFactors?.listing_date) : undefined;
      const transplantDate = transplantFactors?.transplant_date ? this.parseDisplayDateUi(transplantFactors?.transplant_date) : undefined;

      // transplantProgram
      const transplantProgram = this.transplantProgram.find((item: Hospital) => {
        return hospitalId === item._id.$oid;
      });
      const transplantProgramName = transplantProgram && transplantProgram.hospital_name_info && transplantProgram.hospital_name_info.abbreviation ? transplantProgram?.hospital_name_info.abbreviation : '-';
      const relatedJourneys = journey.related_journeys || [];

      return {
        _id: journey._id,
        referredOrgan: referredOrgan || "-",
        referralDate: referralDate || "-",
        journeyStatus: journeyStatus? journeyStatus : "-",
        journeyPhase: journeyPhase? journeyPhase : "-",
        listingDate:  listingDate || "-",
        transplantDate: transplantDate || "-",
        transplantProgram:  transplantProgramName || "-",
        clusteredWith: relatedJourneys.length > 0 ? this.$t('yes').toString() : this.$t('no').toString(),
      };

      // TODO: clustered with , Transplant Date
    });
    return rows;
  }

  // Is the RECIPIENT_OOP_JOURNEYS enabled
  get isOopJourneysEnabled(): boolean {
    return this.moduleEnabled(SystemModules.RECIPIENT_OOP_JOURNEYS);
  }

  /**
    * Return journey stage display value
    */
  get stageDisplayValue() {
    return (journey: RecipientJourney): string|undefined => {
      // NOTE: delegate all logic for deriving Phase to vue-x getter to ensure consistency
      return this.journeyStageDisplayValue(journey);
    };
  }

  /**
   * Return journey status display value
   */
  get statusDisplayValue() {
    return (journey: RecipientJourney): string|undefined =>{
      if(journey.stage == JourneyStage.Waitlist && journey.completed) return titleCase(JourneyStatus.Cancelled);
      return this.journeyStatusDisplayValue(journey);
    };
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit("handleErrors", errors);
  }

  public idLookup(): IdLookup {
    let result = {};
    const previousTransplants = this.$refs.previousTransplants as PreviousTransplants;
    if (previousTransplants) {
      Object.assign(result, { ...previousTransplants.idLookup() });
    }
    return result;
  }
}
