
import { Getter, State } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { SaveResult } from '@/types';
import PageTop from "@/components/shared/PageTop.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import Demographics from '@/components/livingDonors/Demographics.vue';
import ContactInformation from '@/components/livingDonors/ContactInformation.vue';
import SideNavLivingDonor from "@/components/livingDonors/side-nav/SideNavLivingDonor.vue";
import GeneralClinicalInformation from '@/components/livingDonors/GeneralClinicalInformation.vue';
import { LivingDonor } from '@/store/livingDonors/types';
import PotentialDuplicateModal from '@/components/livingDonors/PotentialDuplicateModal.vue';
import { EP } from '@/api-endpoints';
import { ACTIVE_REGION_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import DonationInformation from "@/components/livingDonors/DonationInformation.vue";

@Component({
  components: {
    PageTop,
    SaveToolbar,
    Demographics,
    ContactInformation,
    SideNavLivingDonor,
    GeneralClinicalInformation,
    DonationInformation,
    PotentialDuplicateModal
  }
})
export default class NewLivingDonor extends mixins(ValidationUtilsMixin) {
  // State
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  // Getters
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  private dispatchEventsComplete = false;

  get canSaveLivingDonor(): boolean {
    return this.checkAllowed(EP.living_donors.create, 'POST');
  }

  public mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', 'LivingDonors / New'),
      this.$store.dispatch('livingDonors/new'),
      this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM),
      this.$store.dispatch('validations/loadNew', { view: 'living_donors', action: 'new' }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
    });
  }

   // Handle saving triggered by Save Living Donor button
  public performSave(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveLivingDonor as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();

    // Ref for each component required in the patch
    const demographics = this.$refs.demographics as Demographics;
    const contactInfo = this.$refs.contactInfo as ContactInformation;
    const generalClinical = this.$refs.generalClinical as GeneralClinicalInformation;
     const donationInformation = this.$refs.donationInformation as DonationInformation;

    // Extract demographics and general clinical
    const livingDonorPatch: LivingDonor = {
      ...generalClinical.extractPatch(),
      ...donationInformation.extractPatch(),
      ...demographics.extractPatch(potential_duplicate_profile_confirmed),
    };
    // Extract contact info and merge with patient profile
    livingDonorPatch.patient_profile = {
      ...livingDonorPatch.patient_profile,
      ...contactInfo.extractPatch().patient_profile,
    };

    // Extract and add measurement if exists
    if (!generalClinical.isEmpty) {
      livingDonorPatch.measurements = [generalClinical.extractMeasurementPatch()];
    }

    // Clear previous errors
    (this.$refs.validations as any).reset();

    // Attempt to create the livingDonor
    this.$store.dispatch('livingDonors/saveLivingDonor', { livingDonorId: null, livingDonor: livingDonorPatch }).then((success: SaveResult) => {
      // Get the Client ID assigned to the new livingDonor
      const clientId = success.responseData.living_donor.client_id;
      // Navigate to living donor edit
      this.$router.push({ name: 'edit-living-donor', params: { id: clientId } });
    }).catch((error: SaveResult) => {
      // Handle errors on the form
      this.handleErrors(error);
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.saveLivingDonor as SaveToolbar;
      // Show appropriate saving notification
      if (saveToolbar) {
        saveToolbar.stopSaving(error);
      }
    });
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult|SaveResult[]): void {
     const idLookup: IdLookup = {
      ...(this.$refs.demographics as Demographics).idLookup,
      ...(this.$refs.contactInfo as ContactInformation).idLookup,
      ...(this.$refs.generalClinical as GeneralClinicalInformation).idLookup,
      ...(this.$refs.donationInformation as DonationInformation).idLookup(),
    };

    // Standardize errors to an array of SaveResult objects to faciliate duplicate check
    errors = Array.isArray(errors) ? errors : [errors];

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
    errors.forEach((err: SaveResult) => {
      (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(err,'performSave');
    });
  }
}
