
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { APIRoute, APIBaseRoute, APINakedRoute, EP } from '@/api-endpoints';
import { UserDetails } from '@/store/userAccounts/types';
import { mixins } from 'vue-class-component';
import {UrlUtilsMixin} from "@/mixins/url-utils-mixin";

interface LoginState {
  user: string|null;
}

interface User {
  title: string;
  value: string;
  code: string;
}

@Component({
  components: {
    SelectInput
  }
})
export default class UserList extends mixins(UrlUtilsMixin) {
  @Prop({ default: false }) fusionAuth!: boolean;
  @Prop({ default: undefined }) userToken!: boolean;

  @State(state => state.userAccounts.userList) private userList!: any;
  @State(state => state.pageState.currentPage.LoginState) private editState!: LoginState;

  @Getter('getUserEntries', { namespace: 'userAccounts' }) private getUserEntries!: UserDetails[]|undefined|null;
  @Getter('showUserSelect', { namespace: 'features' }) private showUserSelect!: boolean;

  selectedUserId: string|null = '';

  /**
   * show oneid login only on /access_token/fusionauth
   *
   * @returns boolean
   */
  get hasAccessTokenURL(): boolean { return window.location.href.indexOf('access_token/fusionauth') > -1; }

  /**
   * get ehealth oneid url
   *
   * @returns string
   */
  get eHealthURL(): string { return APIBaseRoute(EP.eHealth.login); }

  /**
   * update local value with user id
   *
   * @returns {string}
   */
  updateUserId() {
    this.selectedUserId = this.editState.user;
  }

  /**
   * check for user selected
   *
   * @returns {boolean}
   */
  checkForUser() {
    return this.editState.user && this.editState.user.length > 0 ? true : false;
  }

  /**
   * get post url
   *
   * @returns {string}
   */
  get getPostUrl(): string {
    return APIRoute(EP.fusionAuth.user_select);
  }

  mounted(): void {
    if (this.showUserSelect) {
      const payload = this.fusionAuth ? this.userToken : undefined;
      this.initializeForm();
      setTimeout(()=>{
        this.$store.dispatch('userAccounts/getUserLogins', payload);
      }, 2000);
    } else {
      this.initializeForm();
    }
  }

  /**
   * set access_type (either fusionauth or oneid)
   * used to determine what the next stage of authentication will be
   *
   * @param type string
   * @param replace parameters to replace in path
   * @returns {string}
   */
  prepareRequest(type: string) {
    localStorage.removeItem('access_type');
    localStorage.setItem('access_type', type);
  }

  /**
   * get user list
   *
   * @returns any[]
   */
  get getFormattedList(): User[]|null {
    if (!this.getUserEntries) return null;
    // build list of users with value to send back to api as id/openid
    const users = this.getUserEntries.map((item: any) => {
      const fullName: any = `${item.first_name} ${item.last_name}`;
      const id: any = item._id ? item._id.$oid : null;
      return { title: fullName, value: fullName, code: id };
    });
    return users;
  }

  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'LoginState',
      value: {
        user: null
      }
    });
  }
}

