import Vue from 'vue';
import { Getter } from 'vuex-class';
import { uniqueElements } from '@/utils';
import Component from 'vue-class-component';
import { CtrErrorContext, UNKNOWN } from '@/types';
import { Allocation, OfferOutcomeContext } from '@/store/allocations/types';

@Component
export class AllocationErrorsMixin extends Vue {
  @Getter('allocationIposProgram', { namespace: 'allocations' }) allocationIposProgram!: string;
  @Getter('parseCtrUnavailableFromString', { namespace: 'allocations' }) parseCtrUnavailableFromString!: (ctrUnavailableMessage: string) => CtrErrorContext[];
  @Getter('parseCtrUnavailableFromAllocation', { namespace: 'allocations' }) parseCtrUnavailableFromAllocation!: (allocation: Allocation) => CtrErrorContext[];

  /**
   * get error message
   *
   * @param error error object
   * @param organCode organ code, optional
   * @returns {string} error message string
   */  
  getErrorMessage(error: any, organCode = null) {
    const error_message = new Set();
    // 500 - internal server error from AS is not an array, so we process the message accordingly..
    if(Array.isArray(error)) {
      (error || []).map((eachError: any) => 
        error_message.add(this.$t(`${eachError.error_message}`))
      );
    } else {
      error_message.add(this.$t(`${error.message}`));
    }

    // If needed to look at actual error message from allocation service, adding as a warning to console.
    console.warn(JSON.stringify(error));

    if(error_message) {
      return Array.from(error_message).join(" \n");
    } else {
      return JSON.stringify(error);
    }
  }

  /*
   * Show 'CTR error workflow' popup for Run Allocation scenarios
   *
   * @param actionId string code indicating which scenario triggered the errors
   * @param ctrErrors array of CTR Error Contexts based on Allocation in response payload
   */
  displayAllocationErrorOutcomeNotification(actionId: string, ctrErrors: CtrErrorContext[]): void {
    // Outcome warnings are based on CTR Error IDs e.g. attempting to sync HSP offer to CTR
    const warningMessages = ctrErrors.map((warning: CtrErrorContext): string => {
      const warningKey = `warning.${this.allocationIposProgram}.${warning.ctr_error_id || UNKNOWN}`;
      const errorMessage = warning.ctr_error_message || UNKNOWN;
      const rawParsedValue = warning.parsedValue || UNKNOWN;
      const parsedValue = this.$te(rawParsedValue) ? this.$t(rawParsedValue).toString() : rawParsedValue;
      return this.$te(warningKey) ? this.$t(warningKey, { errorMessage, parsedValue }).toString() : warning.ctr_error_message;
    });

    // Fetch CTR workflow instructions if there are any
    const rawInstructionsTemplates = ctrErrors.map((ctrError: CtrErrorContext): string => {
      const instructionsKey = `instructions.${this.allocationIposProgram}.${ctrError.ctr_error_id || UNKNOWN}`;
      return this.$te(instructionsKey) ? this.$t(instructionsKey).toString() : this.$t('instructions.generic').toString();
    });

    // Remove any duplicate 'instructions', since that means one 'workaround' resolves multiple issues
    const instructionsTemplates = uniqueElements(rawInstructionsTemplates);

    const context: OfferOutcomeContext = {
      actionId,
      ctrErrors,
      warningMessages,
      instructionsTemplates,
    };

    this.$emit('display-outcome-notification', context);
  }
}
