import Vue from 'vue';
import { format } from 'date-fns';
import { Getter } from "vuex-class";
import Component from 'vue-class-component';
import { Format } from '@/store/utilities/types';

@Component
export class DateUtilsMixin extends Vue {

  @Getter('getDateFormat', { namespace: 'features' }) getDateFormat!: string;
  @Getter('sanitizeDateApi', { namespace: 'utilities' }) sanitizeDateApi!: (date?: string|null) => string|null;
  @Getter('parseDateUi', { namespace: 'utilities' }) parseDateUi!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseTimeUi', { namespace: 'utilities' }) parseTimeUi!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseFormattedDateUi', { namespace: 'utilities' }) parseFormattedDateUi!: (datetime?: string) => string|undefined;
  @Getter('parseDateTimeUi', { namespace: 'utilities' }) parseDateTimeUi!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseFormattedDateTimeUi', { namespace: 'utilities' }) parseFormattedDateTimeUi!: (datetime?: string) => string|undefined;
  @Getter('correctTimeOffset', { namespace: 'utilities' }) correctTimeOffset!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('anotherFormatDate', { namespace: 'utilities' }) anotherFormatDate!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseDisplayDateUi', { namespace: 'utilities' }) parseDisplayDateUi!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('sanitizeDateTimeApi', { namespace: 'utilities' }) sanitizeDateTimeApi!: (date?: string|null, time?: string|null) => string|null;
  @Getter('parseDisplayDateTimeUi', { namespace: 'utilities' }) parseDisplayDateTimeUi!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseDateUiFromDateTime', { namespace: 'utilities' }) parseDateUiFromDateTime!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseTimeUiFromDateTime', { namespace: 'utilities' }) parseTimeUiFromDateTime!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('sanitizeDateWithRequiredTimeApi', { namespace: 'utilities' }) sanitizeDateWithRequiredTimeApi!: (date: string, time?: string) => string|undefined;
  @Getter('sanitizeOptionalDateWithTimeApi', { namespace: 'utilities' }) sanitizeOptionalDateWithTimeApi!: (date?: string, time?: string) => string|undefined;
  @Getter('parseFormattedDateTimeUiFromDateTime', { namespace: 'utilities' }) parseFormattedDateTimeUiFromDateTime!: (datetime?: string) => string|undefined;
  @Getter('parseDisplayDateUiFromDateTime', { namespace: 'utilities' }) parseDisplayDateUiFromDateTime!: (datetime?: string|undefined|null) => string|undefined;
  @Getter('parseDisplayDateTimeUiFromDateTime', { namespace: 'utilities' }) parseDisplayDateTimeUiFromDateTime!: (datetime?: string|undefined|null) => string|undefined;

  // Generate current date-only value for UI build form
  public currentDateUi(): string {
    const currentDateObject = new Date();
    return format(currentDateObject, Format(this.getDateFormat).DATE_FORMAT);
  }

  // Generate current date/time value for API extract patch
  public currentDateTimeApi(): string {
    const currentDateObject = new Date();
    return format(currentDateObject, Format(this.getDateFormat).DATE_TIME_ISO);
  }

  /**
   * Converts boolean value to translated string value
   *
   * @param value string value (-. yes or no)
   * @returns {boolean} input value
   */
  public yesNoUnknown(value: boolean): string {
    if (value == undefined) return '-';
    return value ? this.$t('yes').toString() : this.$t('no').toString();
  }
}
