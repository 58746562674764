
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { State, Getter } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import { Coordinator } from '@/store/coordinators/types';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import SelectInput from "@/components/shared/SelectInput.vue";
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { RecipientReferralAttributes, RecipientReferralFactors, ReferralDecision, RecipientJourney, JourneyDurations } from '@/store/recipientJourney/types';
import { RecipientReferralDecision, RecipientReferralDecisionReason, RecipientReferralDecisionValue, FINAL_DECISION_CONFIRMATION } from '@/store/lookups/types';
import { ReferralSectionPageState } from '@/components/organs/shared/_ReferralSection.vue';
import { ObjectId } from "@/store/types";

export interface ReferralDecisionPageState {
  referralDecisionDate?: string;
  recipientCoordinator?: string;
  referralDecision?: number;
  referralDecisionReasonForDeclined?: number;
  referralDecisionReasonForCancelled?: number;
  comments?: string;
}

export interface ReferralDecisionRow {
  _id: { $oid: string };
  referralDecisionDate?: string;
  recipientCoordinatorName?: string;
  referralDecisionValue?: string;
  referralDecisionReasonValue?: string;
}

@Component({
  components: {
    DateInput,
    TextInput,
    SubSection,
    SelectInput,
    TextAreaInput,
  }
})
export default class ReferralDecisionSection extends mixins(DateUtilsMixin) implements SaveableSection {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.referralSection) editState!: ReferralSectionPageState;
  @State(state => state.journeyState.selectedReferralDecision) selectedReferralDecision!: ReferralDecision;
  @State(state => state.lookups.recipient_referral_decision) recipientReferralDecisionLookup!: RecipientReferralDecision[];
  @State(state => state.journeyState.selectedJourney.stage_attributes.referral) referralAttributes!: RecipientReferralAttributes;
  @State(state => state.journeyState.journeyDurations) journeyDurations!: JourneyDurations;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isReferredWith', { namespace: 'journeyState' }) isReferredWith!: boolean;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('referredWithJourneys', { namespace: 'journeyState' }) referredWithJourneys!: RecipientJourney[];
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('hasFinalReferral', { namespace: 'journeyState' }) hasFinalReferral!: boolean;
  @Getter('referralWaitDays', { namespace: 'journeyState' }) referralWaitDays!: number|null;
  @Getter('hasReferralDetails', { namespace: 'journeyState' }) hasReferralDetails!: boolean;
  @Getter('referralDecisions', { namespace: 'journeyState' }) referralDecisions!: ReferralDecision[];
  @Getter('hasReferringPhysicianDetails', { namespace: 'journeyState' }) hasReferringPhysicianDetails!: boolean;
  @Getter('referralDecisionOptions', { namespace: 'lookups' }) referralDecisionOptions!: RecipientReferralDecision[];
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: { code: string; value: string, hospital_assignments: string[], expiry_date: string|undefined }[];
  @Getter('isCoordinator', { namespace: 'users' }) isCoordinator!: boolean;
  @Getter('getUserCoordinatorId', { namespace: 'users' }) userCoordinatorId!: ObjectId;
  
  @Prop({ default: false }) newJourney!: boolean
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if we meet all the qualifications to create/edit this section
   *
   * @return {boolean} true if we can edit
   */
  get canEdit(): boolean {
    // Can't create decisions on a new or completed journey
    if (this.newJourney  || this.journey.completed) return false;
    // Can't have a final referral
    return !this.hasFinalReferral;
  }
  
  /**
   * Gets table configuration for Referral Decisions.
   * 
   * @return {TableConfig} Referral Decisions table configration
   */
  get referralDecisionTableConfig(): TableConfig {
    let referralDecisionRows: ReferralDecisionRow[] = [];
    // Total Wait for Referral relies on both decision events and the selected journey itself
    // Note: including the journey in this condition ensures it will be re-calculated on journey changes
    if (!!this.editState && !!this.referralDecisions && !!this.journey) {
      referralDecisionRows = this.referralDecisionRows(this.referralDecisions);
    }
    // Only show 'total wait' group header row when journey has referral decisions
    const showTotalDaysRow = referralDecisionRows.length > 0;
    return {
      data: showTotalDaysRow ? [
        {
          referralDecisionValue: this.$t('total_wait_for_referral').toString(), // use second-to-last column field, to ensure label is next to days
          children: referralDecisionRows,
        }
      ] : referralDecisionRows,
      groupOptions: {
        enabled: showTotalDaysRow,
        headerPosition: 'bottom'
      },
      columns: [
        { label: this.$t('referral_decision_date').toString(), field: 'referralDecisionDate', width: '20%' },
        { label: this.$t('recipient_coordinator').toString(), field: 'recipientCoordinatorName', width: '20%' },
        { label: this.$t('referral_decision').toString(), field: 'referralDecisionValue', width: '30%' },
        { label: this.$t('referral_decision_reason').toString(), field: 'referralDecisionReasonValue', width: '30%', headerField: this.totalWaitForReferral, tdClass: 'summary-column' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canEdit,
      createText: this.$t('create_referral_decision').toString(),
    };
  }

  /**
   * Get a string representation of the total number of days waiting for the Referral stage
   * @returns {string} number of wait days as text
   */
  private totalWaitForReferral(rowObj: any): string {
    // referral total days may be zero
    const totalDays = this.referralWaitDays;
    if (totalDays == null) {
      return '-';
    }
    if (totalDays === 1) {
      return this.$t('1_day').toString();
    } else if (totalDays < 0) {
      return this.$t('0_days').toString();
    } else {
      return `${totalDays} ${this.$t('days').toString()}`;
    }
  }

  /**
   * Get a boolean representation when a referral decision is selected
   * 
   * @returns {boolean} true when user has loaded a referral decision
   */  
  get existingDecision(): boolean {
    return !!this.selectedReferralDecision;
  }

  /**
   * Returns an array options for Referral Decision Reason for Cancelled
   * 
   * Fetches the Reason subtable for the Cancelled entry of the Referral Decision lookup table
   * 
   * @returns {RecipientReferralDecisionReason[]} reasons for cancelling a referral
   */
  get referralDecisionReasonCancelledLookup(): RecipientReferralDecisionReason[] {
    if (!this.referralDecisionOptions) {
      return [];
    }
    const declined = this.referralDecisionOptions.find((decision: RecipientReferralDecision) => {
      return decision.code === RecipientReferralDecisionValue.Cancelled;
    });
    if (!declined) {
      return [];
    }
    return declined.sub_tables.recipient_referral_decision_reason;
  }

  /**
   * Returns an array options for Referral Decision Reason for Declined
   * 
   * Fetches the Reason subtable for the Declined entry of the Referral Decision lookup table
   * 
   * @returns {RecipientReferralDecisionReason[]} reasons for declining a referral
   */
  get referralDecisionReasonDeclinedLookup(): RecipientReferralDecisionReason[] {
    if (!this.referralDecisionOptions) {
      return [];
    }
    const declined = this.referralDecisionOptions.find((decision: RecipientReferralDecision) => {
      return decision.code === RecipientReferralDecisionValue.Declined;
    });
    if (!declined) {
      return [];
    }
    return declined.sub_tables.recipient_referral_decision_reason;
  }

  /**
   * Return text for confirmation prompt message if final decision
   * 
   * Check the decsion code in the lookups and see if this is a final decsion,
   * if so return a message for the confirm prompt
   * 
   * @returns {string|undefined} text for confirmation prompt or undefined
   */
  get confirmationText(): string|undefined {
    // Ensure we have all necessary data
    if (!this.editState || !this.referralDecisionOptions) {
      return undefined;
    }
    // Get selected decision
    const decisionCode = this.editState.referral ? this.editState.referral.referralDecision : undefined;
    // Is this a final decision? 
    const finalDecision = this.referralDecisionOptions.find((item: RecipientReferralDecision) => {
      return decisionCode == item.code && item.final === true;
    });
    if (!finalDecision) {
      return undefined;
    }
    return FINAL_DECISION_CONFIRMATION;
  }

  /**
   * Clears all save notifications shown by the form.
   * 
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveReferralDecision as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  /**
   * Saves the form edit state.
   * 
   * Prepares an update payload for Referral Atributes, dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save ider that handles this form area
    const saveProvider = this.$refs.saveReferralDecision as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'referralDecision');
    // Generate payload based on current edit state
    const referralAttributesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipient.client_id,
      referralAttributes: referralAttributesPatch,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveReferralDecision', payload).then((success: SaveResult) => {
      // Reload decision list
      this.reloadReferralDecisionHistory();
      // Clear form state
      this.createReferralDecision();
      // Show success notification
      saveProvider.registerSaveResult(success);
      // Clear errors
      this.$emit('clear');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   * 
   * Delegates the logic of building the patch to a local private method
   * 
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.referral) {
      return {};
    }
    return this.extractReferralAttributesPatch(this.editState.referral);
  }

  /**
   * Build page state for the Referral Decision subsection
   * 
   * @param referralDecision a Journey Referral Decision already mapped from a recipient event
   * @returns {ReferralDecisionPageState} page state for the Referral Decision form
   */
  public buildReferralDecisionPageState(referralDecision?: ReferralDecision): ReferralDecisionPageState {
    const defaultState: ReferralDecisionPageState = {
      referralDecisionDate: this.currentDateUi(),
      recipientCoordinator: this.isCoordinator ? this.userCoordinatorId.$oid : undefined
    };
    if (!!referralDecision) {
      const decisionDate = this.parseDateUiFromDateTime(referralDecision.referralDecisionDate);
      const decisionCode = referralDecision.referralDecisionCode;
      const decisionCoordinatorId = referralDecision.recipientCoordinator;
      const decisionReasonCode = referralDecision.referralDecisionReasonCode; 
      let referralDecisionReasonForDeclined: number|undefined = undefined;
      let referralDecisionReasonForCancelled: number|undefined = undefined;
      switch (decisionCode) {
        case RecipientReferralDecisionValue.Declined:
          referralDecisionReasonForDeclined = decisionReasonCode || undefined;
          break;
        case RecipientReferralDecisionValue.Cancelled:
          referralDecisionReasonForCancelled = decisionReasonCode || undefined;
          break;
      }
      // Build page stage
      const result = {
        referralDecisionDate: decisionDate || defaultState.referralDecisionDate,
        recipientCoordinator: decisionCoordinatorId,
        referralDecision: decisionCode,
        referralDecisionReasonForDeclined: referralDecisionReasonForDeclined,
        referralDecisionReasonForCancelled: referralDecisionReasonForCancelled,
        comments: referralDecision.referralComments,
      };
      return result;
    }
    return defaultState;
  }

  // Build page state for the Referral Decision subsection based on arbitrary journey
  private buildReferralDecisionPageStateFromJourney(journey: RecipientJourney): ReferralDecisionPageState {
    // Check specified journey referral factors
    const factors: RecipientReferralFactors = journey.stage_attributes?.referral?.factors || {};
    const referralDecisionDate = this.parseDateUi(factors.decision_date);
    const referralDecision = factors.decision_code || undefined;
    const decisionReasonCode = factors.decision_reason_code; 
    let referralDecisionReasonForDeclined: number|undefined = undefined;
    let referralDecisionReasonForCancelled: number|undefined = undefined;
    switch (referralDecision) {
      case RecipientReferralDecisionValue.Declined:
        referralDecisionReasonForDeclined = decisionReasonCode || undefined;
        break;
      case RecipientReferralDecisionValue.Cancelled:
        referralDecisionReasonForCancelled = decisionReasonCode || undefined;
        break;
    }

    // Define editable page state
    const result: ReferralDecisionPageState = {
      referralDecisionDate,
      recipientCoordinator: factors?.decision_coordinator_id?.$oid || undefined,
      referralDecision,
      referralDecisionReasonForDeclined,
      referralDecisionReasonForCancelled,
      comments: factors.decision_comments || undefined,
    };
    return result;
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'factors.referral_received_date' : 'rd-recieved-date',
    'factors.decision_date'          : 'journey-referral-date',
    'factors.decision_coordinator_id': 'journey-referral-coordinator',
    'factors.decision_code'          : 'journey-referral-decision',
    'factors.decision_reason_code'   : 'referral_reason_code',
  };

  // Private methods

  /**
   * Get new decision events which will refresh the table 
   */
  private reloadReferralDecisionHistory(): void {
    // Begin loading recipient decision events
    this.$store.dispatch('recipients/loadDecisionEvents', this.recipientId);
    // Reload the recipient and journey data
    this.$store.dispatch('recipients/get', this.recipientId).then(() => {
      this.$store.dispatch('journeyState/getJourney', this.journeyId);
    });
  }

  /**
   * Gets form edit state changes as a Referral Attributes patch
   * 
   * Changes to Referral Factors are nested within the patch under the 'factors' key
   * 
   * @param referral form edit state containing changes
   * @returns {RecipientReferralAttributes} patch object containing field changes
   */
  private extractReferralAttributesPatch(referral: ReferralDecisionPageState): RecipientReferralAttributes {
    /**
     * Identify which reason code parameter to send based on selected decision. If a decision that has no associated
     * reasons is selected, then we must send a null decision reason code to remove any prior reason if there was one.
     */
    const decisionCode = referral.referralDecision;
    let decisionReasonCode: number|null = null;
    /**
     * Use double-equals instead of triple-equals to determine which decision reason was selected, because even numeric
     * values in the form edit state are strings. Using the triple-equals pattern only returns true if the compared
     * values have the same type, which when comparing form edit state values against lookup code numbers will never be
     * the case.
     */
    if (decisionCode == RecipientReferralDecisionValue.Declined) {
      decisionReasonCode = referral.referralDecisionReasonForDeclined || null;
    } else if (decisionCode == RecipientReferralDecisionValue.Cancelled) {
      decisionReasonCode = referral.referralDecisionReasonForCancelled || null;
    }

    // Build patch for Referral Factors
    const factorsPatch: RecipientReferralFactors = {
      decision_date: this.sanitizeDateApi(referral.referralDecisionDate) || null,
      decision_coordinator_id: referral.recipientCoordinator ? { $oid: referral.recipientCoordinator } : undefined,
      decision_code: decisionCode || null,
      decision_reason_code: decisionReasonCode || null,
      decision_comments: referral.comments || null,
    };
    // Build patch for Referral Attributes with Factors as a nested object
    const result: RecipientReferralAttributes = {
      factors: factorsPatch,
    };
    return result;
  }

  /**
   * Returns whether or not to show Referral Decision Reason for Cancelled
   * 
   * @returns {boolean} true if the form area should be shown, false if not
   */
  private showReasonForCancelled(): boolean {
    if (!this.editState || !this.editState.referral || !this.editState.referral.referralDecision) {
      return false;
    }
    /**
     * Use double-equals instead of triple-equals to determine which decision reason was selected, because even numeric
     * values in the form edit state are strings. Using the triple-equals pattern only returns true if the compared
     * values have the same type, which when comparing form edit state values against lookup code numbers will never be
     * the case.
     */
    return this.editState.referral.referralDecision == RecipientReferralDecisionValue.Cancelled;
  }

  /**
   * Returns whether or not to show Referral Decision Reason for Declined
   * 
   * @returns {boolean} true if the form area should be shown, false if not
   */
  private showReasonForDeclined(): boolean {
    if (!this.editState || !this.editState.referral || !this.editState.referral.referralDecision) {
      return false;
    }
    /**
     * Use double-equals instead of triple-equals to determine which decision reason was selected, because even numeric
     * values in the form edit state are strings. Using the triple-equals pattern only returns true if the compared
     * values have the same type, which when comparing form edit state values against lookup code numbers will never be
     * the case.
     */
    return this.editState.referral.referralDecision == RecipientReferralDecisionValue.Declined;
  }

  /**
   * Gets table row data representing Referral Decisions for the selected recipient.
   * 
   * @param decisions Referral Decisions already mapped from events
   * @returns {ReferralDecisionRow[]} Referral Decision rows 
   */
  private referralDecisionRows(decisions?: ReferralDecision[]): ReferralDecisionRow[] {
    if (!decisions) {
      return [];
    }
    const rows = decisions.map((decision: ReferralDecision): ReferralDecisionRow => {
      const row: ReferralDecisionRow = {
        _id: decision._id,
        referralDecisionDate: this.parseDisplayDateUiFromDateTime(decision.referralDecisionDate) || '-',
        recipientCoordinatorName: this.recipientCoordinatorName(decision.recipientCoordinator) || '-',
        referralDecisionValue: this.referralDecisionValue(decision.referralDecisionCode) || '-',
        referralDecisionReasonValue: this.referralDecisionReasonValue(decision.referralDecisionCode, decision.referralDecisionReasonCode) || '-',
      };
      return row;
    });
    return rows;
  }

  /**
   * Gets display name string for a Recipient Coordinator
   * 
   * @param recipientCoordinatorId the ID number for the Recipient Coordinator
   * @return {string|undefined} Recipient Coordinator name if one exists, otherwise undefined
   */
  private recipientCoordinatorName(recipientCoordinatorId?: string): string|undefined {
    // Fetch possible coordinator options
    const possibleCoordinators = this.coordinatorOptions;
    // Select option with code equal to specified Object ID string
    const theCoordinatorOption = possibleCoordinators.find((option: { code: string; value: string }) => {
      return option.code === recipientCoordinatorId;
    });
    if (!theCoordinatorOption) {
      return undefined;
    }
    return theCoordinatorOption.value;
  }

  /**
   * Gets text representation of an Referral Decision
   * 
   * @param decisionCode numeric code from lookups representing a Referral Decision
   * @returns {string|undefined} Referral Decision as text
   */
  private referralDecisionValue(decisionCode?: number): string|undefined {
    const lookupTable = this.referralDecisionOptions;
    if (!lookupTable) {
      return undefined;
    }
    const decisionEntry = lookupTable.find((decision: RecipientReferralDecision) => {
      return decision.code == decisionCode;
    });
    if (!decisionEntry) {
      return undefined;
    }
    return decisionEntry.value;
  }

  /**
   * Gets text representation of an Referral Decision Reason
   * 
   * @param decisionCode numeric code representing a Referral Decision
   * @param reasonCode numeric code representing a Referral Decision Reason
   * @returns {string} Referral Decision Reason as text
   */
  private referralDecisionReasonValue(decisionCode?: number, reasonCode?: number): string|undefined {
    const lookupTable = this.referralDecisionOptions;
    if (!lookupTable) {
      return undefined;
    }
    const decisionEntry = lookupTable.find((decision: RecipientReferralDecision) => {
      return decision.code == decisionCode;
    });
    if (!decisionEntry || !decisionEntry.sub_tables) {
      return undefined;
    }
    const subtable = decisionEntry.sub_tables.recipient_referral_decision_reason;
    if (!subtable) {
      return undefined;
    }
    const reasonEntry =  subtable.find((reason: RecipientReferralDecisionReason) => {
      return reason.code == reasonCode;
    });
    if (!reasonEntry) {
      return undefined;
    }
    return reasonEntry.value;
  }

  

  /**
   * Builds form edit state based on selected document
   * 
   * @param event select event
   */
  private selectReferralDecision(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.editState || !this.referralDecisions) {
      return;
    }
    // Find the selected source document
    const found = this.referralDecisions.find((decision: ReferralDecision) => {
      return decision._id && decision._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Store the selection
    this.$store.commit('journeyState/selectReferralDecision', found);
    // Build form state based on selected document
    const decisionForm: ReferralDecisionPageState = this.buildReferralDecisionPageState(this.selectedReferralDecision);
    Vue.set(this.editState, 'referral', decisionForm);
  }

  /**
   * Resets edit state to prepare for entering a new document
   */
  private createReferralDecision(): void {
    // Clear stored selection
    this.$store.commit('journeyState/clearReferralDecision');
    // Build empty form state
    const decisionForm: ReferralDecisionPageState = this.buildReferralDecisionPageState();
    Vue.set(this.editState, 'referral', decisionForm);
  }

  // Copy referral decision from a referred with journey 
  private copyFromJourney(journeyId: string): void {
    // Clear stored selection
    this.$store.commit('journeyState/clearReferralDecision');

    // Build form state based on specified journey referral
    const recipientJourneys: RecipientJourney[] = this.recipient.journeys || [];
    const relatedJourney = recipientJourneys.find((j: RecipientJourney) => j._id?.$oid === journeyId);
    if (!relatedJourney) return;

    const decisionForm: ReferralDecisionPageState = this.buildReferralDecisionPageStateFromJourney(relatedJourney);
    Vue.set(this.editState, 'referral', decisionForm);
  }
}
