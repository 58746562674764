
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { State, Getter } from 'vuex-class';
import { GenericCodeValue, ObjectId } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Prop, Component, Vue } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectInput from "@/components/shared/SelectInput.vue";
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { RecipientJourney, RecipientAssessmentAttributes, RecipientAssessmentFactors, AssessmentDecision, JourneyDurations } from '@/store/recipientJourney/types';
import { RecipientAssessmentDecision, RecipientAssessmentDecisionReason, RecipientAssessmentDecisionValue, RecipientAssessmentDecisionReasonValue, AbsoluteContraindication, RelativeContraindication, Organ, FINAL_DECISION_CONFIRMATION } from '@/store/lookups/types';
import { AssessmentSectionPageState } from '@/components/organs/shared/_MedicalAssessmentSection.vue';

export interface AssessmentPageState {
  assessmentDate?: string;
  recipientCoordinator?: string;
  assessmentDecision?: number;
  assessmentDecisionReasonCode?: number;
  reasonCancelled?: number;
  reasonNotEligible?: number;
  reasonFurtherTreatment?: number;
  reasonFurtherTreatmentOther?: string;
  absoluteContraindications?: any;
  relativeContraindications?: any;
  comments?: string;
}

export interface AssessmentRow {
  _id: { $oid: string };
  assessmentDate?: string;
  recipientCoordinator?: string;
  assessmentDecision?: string;
  assessmentDecisionValue?: string;
  assessmentDecisionReasonValue?: string;
}

@Component({
  components: {
    DateInput,
    TextInput,
    SubSection,
    SelectInput,
    TextAreaInput,
  }
})

export default class AssessmentStatusSection extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.assessmentSection) editState!: AssessmentSectionPageState;
  @State(state => state.journeyState.journeyDurations) journeyDurations!: JourneyDurations;
  @State(state => state.journeyState.selectedAssessmentDecision) selectedAssessmentDecision!: AssessmentDecision;
  @State(state => state.journeyState.selectedJourney.stage_attributes.assessment) assessmentAttributes!: RecipientAssessmentAttributes;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('hasFinalReferral', { namespace: 'journeyState' }) hasFinalReferral!: boolean;
  @Getter('hasFinalAssessment', { namespace: 'journeyState' }) hasFinalAssessment!: boolean;
  @Getter('assessmentWaitDays', { namespace: 'journeyState' }) assessmentWaitDays!: number|null;
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: { code: string; value: string, hospital_assignments: string[], expiry_date: string|undefined }[];  
  @Getter('assessmentDecisions', { namespace: 'journeyState' }) assessmentDecisions!: AssessmentDecision[];
  @Getter('assessmentDecisionOptions', { namespace: 'lookups' }) assessmentDecisionOptions!: RecipientAssessmentDecision[];
  @Getter('isCoordinator', { namespace: 'users' }) isCoordinator!: boolean;
  @Getter('getUserCoordinatorId', { namespace: 'users' }) userCoordinatorId!: ObjectId;

  // Props
  @Prop({ default: false }) newJourney!: boolean
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if we meet all the qualifications to create/edit this section
   *
   * @return {boolean} true if we can edit
   */
  get canEdit(): boolean {
    // Can't create decisions on a new or completed journey
    if (this.newJourney  || this.journey.completed) return false;
    // Must have a final referral and can't have a final assessment
    return this.hasFinalReferral && !this.hasFinalAssessment;
  }

  /**
   * Gets table configuration for Medical Assessment.
   * 
   * @return {TableConfig} Medical Assessment table configration
   */
  get medicalAssessmentTableConfig(): TableConfig {
    let medicalAssessmentRows: AssessmentRow[] = [];
    // Total Wait for Assessment relies on both decision events and the selected journey itself
    // Note: including the journey in this condition ensures it will be re-calculated on journey changes
    if (!!this.editState && !!this.editState.assessment && !!this.journey) {
      medicalAssessmentRows = this.medicalAssessmentRows(this.assessmentDecisions);
    }
    // Only show 'total wait' group header row when journey has medical assessment decisions
    const showTotalDaysRow = medicalAssessmentRows.length > 0;
    return {
      data: showTotalDaysRow ? [
        {
          assessmentDecisionValue: this.$t('total_wait_assessment').toString(), // use second-to-last column field, to ensure label is next to days
          children: medicalAssessmentRows,
        }
      ] : medicalAssessmentRows,
      groupOptions: {
        enabled: showTotalDaysRow,
        headerPosition: 'bottom'
      },
      columns: [
        { label: this.$t('assessment_date').toString(), field: 'assessmentDate', width: '20%' },
        { label: this.$t('recipient_coordinator').toString(), field: 'recipientCoordinator', width: '20%' },
        { label: this.$t('assessment_decision').toString(), field: 'assessmentDecisionValue', width: '30%' },
        { label: this.$t('assessment_decision_reason').toString(), field: 'assessmentDecisionReasonValue', width: '230', headerField: this.totalWaitForAssessment, tdClass: 'summary-column' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canEdit,
      createText: this.$t('create').toString()
    };
  }

  /**
   * Get a boolean representation if an assessment is selected
   * 
   * @returns {boolean} true when user has loaded an assessment
   */  
  get existingDecision(): boolean {
    return !!this.selectedAssessmentDecision;
  }

  /**
   * Returns an array of options for Reason for Cancelled
   * 
   * Fetches the Reason subtable for the Cancelled entry of the Assessment Decision lookup table
   * 
   * @returns {RecipientAssessmentDecisionReason[]} reasons for declining a referral
   */
  get reasonCancelledLookup(): RecipientAssessmentDecisionReason[] {
    if (!this.assessmentDecisionOptions) {
      return [];
    }
    const cancelled = this.assessmentDecisionOptions.find((decision: RecipientAssessmentDecision) => {
      return decision.code === RecipientAssessmentDecisionValue.Cancelled;
    });
    if (!cancelled || !cancelled.sub_tables) {
      return [];
    }
    return cancelled.sub_tables.recipient_assessment_decision_reason_codes;
  }

  /**
   * Returns an array of options for Reason for Not Eligible
   * 
   * Fetches the Reason subtable for the Not Eligible entry of the Assessment Decision lookup table
   * 
   * @returns {RecipientAssessmentDecisionReason[]} reasons for declining a referral
   */
  get reasonNotEligibleLookup(): RecipientAssessmentDecisionReason[] {
    if (!this.assessmentDecisionOptions) {
      return [];
    }
    const notEligible = this.assessmentDecisionOptions.find((decision: RecipientAssessmentDecision) => {
      return decision.code === RecipientAssessmentDecisionValue.NotEligible;
    });
    if (!notEligible || !notEligible.sub_tables) {
      return [];
    }
    return notEligible.sub_tables.recipient_assessment_decision_reason_codes;
  }

  /**
   * Returns an array of options for Reason for Further Treatment / Testing Required
   * 
   * Fetches the Reason subtable for the Further Treatment entry of the Assessment Decision lookup table
   * 
   * @returns {RecipientAssessmentDecisionReason[]} reasons for declining a referral
   */
  get reasonFurtherTreatmentLookup(): RecipientAssessmentDecisionReason[] {
    if (!this.assessmentDecisionOptions) {
      return [];
    }
    const furtherTreatment = this.assessmentDecisionOptions.find((decision: RecipientAssessmentDecision) => {
      return decision.code === RecipientAssessmentDecisionValue.FurtherTreatment;
    });
    if (!furtherTreatment || !furtherTreatment.sub_tables) {
      return [];
    }
    const organCode = this.organCode;
    const filteredFurtherTreatmentReasons = furtherTreatment.sub_tables.recipient_assessment_decision_reason_codes.filter((decision: RecipientAssessmentDecisionReason) => {
      return decision.organ_codes == undefined || (decision.organ_codes || []).includes(organCode);
    });
    return filteredFurtherTreatmentReasons;  
  }

  /**
   * Returns an array of options for Absolute Contraindication
   * 
   * Determines if we have any values in the Organ sub_table for the selected Journey
   * 
   * @returns {AbsoluteContraindication[]} all options for absolute contraindication
   */
  get absoluteContraindicationLookup(): AbsoluteContraindication[] {
    if (!this.organLookup || !this.organCode) {
      return [];
    }
    // Retrieve information based on Journey Organ
    const organLookupEntry = this.organLookup.find((organ: Organ) => {
      return organ.code === this.organCode;
    });
    if (!organLookupEntry || !organLookupEntry.sub_tables) {
      return [];
    }
    // Determine which sub table to fetch options from
    let result: AbsoluteContraindication[] = [];
    const adultAbsContraindications = organLookupEntry.sub_tables.absolute_listing_contraindications_adult;
    const pediatricAbsContraindications = organLookupEntry.sub_tables.absolute_listing_contraindications_pediatric;
    // Check if there are any Adult contraindications
    if (Array.isArray(adultAbsContraindications) && adultAbsContraindications.length > 0) {
      result = adultAbsContraindications;
    }
    // Check if there are any Pediatric contraindications
    if (Array.isArray(pediatricAbsContraindications) && pediatricAbsContraindications.length > 0) {
      result = result.concat(pediatricAbsContraindications);
    }
    return result;
  }

  /**
   * Returns an array of options for Relative Contraindication
   * 
   * Determines if we have any values in the Organ sub_table for the selected Journey
   * 
   * @returns {RelativeContraindication[]} all options for relative contraindication
   */
  get relativeContraindicationLookup(): RelativeContraindication[] {
    if (!this.organLookup || !this.organCode) {
      return [];
    }
    // Retrieve information based on Journey Organ
    const organLookupEntry = this.organLookup.find((organ: Organ) => {
      return organ.code === this.organCode;
    });
    if (!organLookupEntry || !organLookupEntry.sub_tables) {
      return [];
    }
    // Determine which sub table to fetch options from
    let result: RelativeContraindication[] = [];
    const adultRelContraindications = organLookupEntry.sub_tables.relative_listing_contraindications_adult;
    const pediatricRelContraindications = organLookupEntry.sub_tables.relative_listing_contraindications_pediatric;
    // Check if there are any Adult contraindications
    if (Array.isArray(adultRelContraindications) && adultRelContraindications.length > 0) {
      result = adultRelContraindications;
    }
    // Check if there are any Pediatric contraindications
    if (Array.isArray(pediatricRelContraindications) && pediatricRelContraindications.length > 0) {
      result = result.concat(pediatricRelContraindications);
    }
    return result;
  }

  /**
   * Get a numeric representation of which Organ is associated with the selected Journey
   * 
   * @returns {number} code that can be used when fetching entries from the Organ lookup table
   */
  get organCode(): number {
    if (!this.journey || !this.journey.organ_code) {
      return 0;
    }
    return this.journey.organ_code;
  }

  /**
   * Get a boolean representation if the save button is usable
   * 
   * @returns {boolean} true when save button is available
   */
  get saveable(): boolean {
    if (!this.editState || !this.editState.assessment) {
      return false;
    }
    const existingJourney = !this.newJourney;
    return existingJourney && !this.existingDecision;
  }

  /**
   * Return text for confirmation prompt message if final decision
   * 
   * Check the decsion code in the lookups and see if this is a final decsion,
   * if so return a message for the confirm prompt
   * 
   * @returns {string|undefined} text for confirmation prompt or undefined
   */
  get confirmationText(): string|undefined {
    // Ensure we have all necessary data
    if (!this.editState || !this.assessmentDecisionOptions) {
      return undefined;
    }
    // Get selected decision
    const decisionCode = this.editState.assessment ? this.editState.assessment.assessmentDecision : undefined;
    // Is this a final decision? 
    const finalDecision = this.assessmentDecisionOptions.find((item: RecipientAssessmentDecision) => {
      return decisionCode == item.code && item.final === true;
    });
    if (!finalDecision) {
      return undefined;
    }
    return FINAL_DECISION_CONFIRMATION;
  }

  /**
   * Clears all save notifications shown by the form.
   * 
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveMedicalAssessment as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  /**
   * Saves the form edit state.
   * 
   * Prepares an update payload for Referral Atributes, dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveMedicalAssessment as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'medicalAssessment');
    // Generate payload based on current edit state
    const medicalAssessmentPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipient.client_id,
      medicalAssessment: medicalAssessmentPatch,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveMedicalAssessment', payload).then((success: SaveResult) => {
      Promise.all([
        // Begin loading recipient decision events
        this.$store.dispatch('recipients/loadDecisionEvents', this.recipientId),
        // Reload the recipient and journey data
        this.$store.dispatch('recipients/get', this.recipientId),
        this.$store.dispatch('journeyState/getJourney', this.journeyId),
      ]).finally(() => {
        // Create Table Row
        this.createAssessmentDecision();
        // Show success notification
        saveProvider.registerSaveResult(success);
        // Clear any form errors
        this.$emit('clear');
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }
  
  /**
   * Gets a patch object representing form edit state changes for this form
   * 
   * Delegates the logic of building the patch to a local private method
   * 
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.assessment) {
      return {};
    }
    return this.extractMedicalAssessmentPatch(this.editState.assessment);
  }

  /**
   * Build page state for the Medical Assessment subsection
   * 
   * @param assessmentDecision a Recipient Journey Assessment Attributes model, including its nested factors
   * @returns {AssessmentPageState} page state for the Medical Assessment form
   */
  public buildAssessmentPageState(assessmentDecision?: AssessmentDecision): AssessmentPageState {
    const defaultState: AssessmentPageState = {
      assessmentDate: this.currentDateUi(),
      recipientCoordinator: this.isCoordinator ? this.userCoordinatorId.$oid : undefined
    };
    if (!!assessmentDecision) {
      const assessmentDate = this.parseDateUiFromDateTime(assessmentDecision.assessmentDate);
      const recipientCoordinatorId = assessmentDecision.recipientCoordinator;
      const assessmentDecisionCode = assessmentDecision.assessmentDecisionCode;
      const assessmentDecisionReasonCode = assessmentDecision.assessmentDecisionReasonCode;
      let reasonCancelled: number|undefined = undefined;
      let reasonNotEligible: number|undefined = undefined;
      let reasonFurtherTreatment: number|undefined = undefined;
      switch (assessmentDecisionCode) {
        case RecipientAssessmentDecisionValue.Cancelled:
          reasonCancelled = assessmentDecisionReasonCode || undefined;
          break;
        case RecipientAssessmentDecisionValue.NotEligible:
          reasonNotEligible = assessmentDecisionReasonCode || undefined;
          break;
        case RecipientAssessmentDecisionValue.FurtherTreatment:
          reasonFurtherTreatment = assessmentDecisionReasonCode || undefined;
          break;
      }
      // Build result
      const result: AssessmentPageState = {
        assessmentDate: assessmentDate || defaultState.assessmentDate,
        recipientCoordinator: recipientCoordinatorId,
        assessmentDecision: assessmentDecisionCode,
        assessmentDecisionReasonCode: assessmentDecisionReasonCode,
        reasonCancelled: reasonCancelled,
        reasonNotEligible: reasonNotEligible,
        reasonFurtherTreatment: reasonFurtherTreatment,
        reasonFurtherTreatmentOther: assessmentDecision.reasonFurtherTreatmentOther,
        absoluteContraindications: assessmentDecision.absoluteContraindicationCode,
        relativeContraindications: assessmentDecision.relativeContraindicationCode,
        comments: assessmentDecision.assessmentComments,
      };
      return result;
    }
    return defaultState;
  }

  // Private methods

  /**
   * Gets form edit state changes as an Assessment Attributes patch
   * 
   * Changes to Assessment Factors are nested within the patch under the 'factors' key
   * 
   * @param assessment form edit state containing changes
   * @returns {RecipientAssessmentAttributes} patch object containing field changes
   */
  private extractMedicalAssessmentPatch(assessment: AssessmentPageState): RecipientAssessmentAttributes {
    /**
     * Identify which reason code parameter to send based on selected decision. If a decision that has no associated
     * reasons is selected, then we must send a null decision reason code to remove any prior reason if there was one.
     */
    const decisionCode = assessment.assessmentDecision;
    let decisionReasonCode: number|null = null;
    /**
     * Use double-equals instead of triple-equals to determine which decision reason was selected, because even numeric
     * values in the form edit state are strings. Using the triple-equals pattern only returns true if the compared
     * values have the same type, which when comparing form edit state values against lookup code numbers will never be
     * the case.
     */
    if (decisionCode == RecipientAssessmentDecisionValue.NotEligible) {
      decisionReasonCode = assessment.reasonNotEligible || null;
    } else if (decisionCode == RecipientAssessmentDecisionValue.FurtherTreatment) {
      decisionReasonCode = assessment.reasonFurtherTreatment || null;
    } else if (decisionCode == RecipientAssessmentDecisionValue.Cancelled) {
      decisionReasonCode = assessment.reasonCancelled || null;
    }
    
    // Clear reasonFurtherTreatmentOther if decisionReasonCode isn't Other
    if (decisionReasonCode != RecipientAssessmentDecisionReasonValue.Other) {
      Vue.set(assessment, 'reasonFurtherTreatmentOther', undefined);
    }
    
    // Clear contraindications if decision is NotEligible and decisionReasonCode is ListingCriteria
    if (decisionCode != RecipientAssessmentDecisionValue.NotEligible && decisionReasonCode != RecipientAssessmentDecisionReasonValue.ListingCriteria) {
      Vue.set(assessment, 'absoluteContraindications', undefined);
      Vue.set(assessment, 'relativeContraindications', undefined);
    }
    
    // Build patch for Assessment Factors
    const factorsPatch: RecipientAssessmentFactors = {
      assessment_decision_date: this.sanitizeDateApi(assessment.assessmentDate),
      assessment_decision_coordinator_id: assessment.recipientCoordinator || null,
      assessment_decision_code: decisionCode || null,
      assessment_decision_reason_code: decisionReasonCode || null,
      assessment_absolute_contraindication_code: assessment.absoluteContraindications || null,
      assessment_relative_contraindication_code: assessment.relativeContraindications || null,
      assessment_decision_reason_other: assessment.reasonFurtherTreatmentOther || null,
      assessment_decision_comments: assessment.comments,
    };
    // Build patch for Assessment Attributes with Factors as a nested object
    const result: RecipientAssessmentAttributes = {
      factors: factorsPatch,
    };
    return result;
  }

  /**
   * Returns whether or not to show Other Reason for Other Treatment/Testing required 
   * 
   * @returns {boolean} true if the form area should be shown, false if not
   */
  private showReasonFurtherTreatmentOther(): boolean {
    if (!this.editState.assessment) {
      return false;
    }
    const assessment = this.editState.assessment || {};
    const isDecisionCriteria = assessment.assessmentDecision == RecipientAssessmentDecisionValue.FurtherTreatment;
    const isReasonCriteria = assessment.reasonFurtherTreatment == RecipientAssessmentDecisionReasonValue.Other;
    return isDecisionCriteria && isReasonCriteria;
  }

  /**
   * Returns whether or not to show Reason for Cancelled
   * 
   * @returns {boolean} true if the form area should be shown, false if not
   */
  private showReasonCancelled(): boolean {
    if (!this.editState || !this.editState.assessment || !this.editState.assessment.assessmentDecision) {
      return false;
    }
    /**
     * Use double-equals instead of triple-equals to determine which decision reason was selected, because even numeric
     * values in the form edit state are strings. Using the triple-equals pattern only returns true if the compared
     * values have the same type, which when comparing form edit state values against lookup code numbers will never be
     * the case.
     */
    return this.editState.assessment.assessmentDecision == RecipientAssessmentDecisionValue.Cancelled;
  }

  /**
   * Returns whether or not to show Reason for Not Eligible
   * 
   * @returns {boolean} true if the form area should be shown, false if not
   */
  private showReasonNotEligible(): boolean {
    if (!this.editState || !this.editState.assessment || !this.editState.assessment.assessmentDecision) {
      return false;
    }
    /**
     * Use double-equals instead of triple-equals to determine which decision reason was selected, because even numeric
     * values in the form edit state are strings. Using the triple-equals pattern only returns true if the compared
     * values have the same type, which when comparing form edit state values against lookup code numbers will never be
     * the case.
     */
    return this.editState.assessment.assessmentDecision == RecipientAssessmentDecisionValue.NotEligible;
  }

  /**
   * Returns whether or not to show Reason for Further Treatment / Testing Required
   * 
   * @returns {boolean} true if the form area should be shown, false if not
   */
  private showReasonFurtherTreatment(): boolean {
    if (!this.editState || !this.editState.assessment || !this.editState.assessment.assessmentDecision) {
      return false;
    }
    /**
     * Use double-equals instead of triple-equals to determine which decision reason was selected, because even numeric
     * values in the form edit state are strings. Using the triple-equals pattern only returns true if the compared
     * values have the same type, which when comparing form edit state values against lookup code numbers will never be
     * the case.
     */
    return this.editState.assessment.assessmentDecision == RecipientAssessmentDecisionValue.FurtherTreatment;
  }

  /**
   * Returns whether or not to show the Absolute Contraindication dropdown
   * 
   * Only show the Absolute Contraindication dropdown if:
   * 1) Assessment Decision is Recipient is not eligible; and,
   * 2) Reason for Decision is Recipient does not meet listing criteria; and,
   * 3) options were found based on Recipient Age and Journey Organ
   * 
   * @returns {boolean} true if options were found
   */
  private showAbsoluteContraindication(): boolean {
    if (!this.editState.assessment) {
      return false;
    }
    const assessment = this.editState.assessment || {};
    const isDecisionCriteria = assessment.assessmentDecision == RecipientAssessmentDecisionValue.NotEligible;
    const isListingCriteria = assessment.reasonNotEligible == RecipientAssessmentDecisionReasonValue.ListingCriteria;
    const hasOptions = !!this.absoluteContraindicationLookup && this.absoluteContraindicationLookup.length > 0;
    return isDecisionCriteria && isListingCriteria && hasOptions;
  }

  /**
   * Returns whether or not to show the Relative Contraindication dropdown
   * 
   * Only show the Relative Contraindication dropdown if:
   * 1) Assessment Decision is Recipient is not eligible; and,
   * 2) Reason for Decision is Recipient does not meet listing criteria; and,
   * 3) options were found based on Recipient Age and Journey Organ
   * 
   * @returns {boolean} true if options were found
   */
  private showRelativeContraindication(): boolean {
    if (!this.editState.assessment) {
      return false;
    }
    const assessment = this.editState.assessment || {};
    const isDecisionCriteria = assessment.assessmentDecision == RecipientAssessmentDecisionValue.NotEligible;
    const isListingCriteria = assessment.reasonNotEligible == RecipientAssessmentDecisionReasonValue.ListingCriteria;
    const hasOptions = !!this.relativeContraindicationLookup && this.relativeContraindicationLookup.length > 0;
    return isDecisionCriteria && isListingCriteria && hasOptions;
  }

  /**
   * Returns a string representation of the total number of days waiting for the Assessment stage
   * @param medicalAssessmentData row group
   * @returns {string} number of wait days as text
   */
  private totalWaitForAssessment(medicalAssessmentData: any): string {
    // assessment days may be zero
    const assessmentDays = this.assessmentWaitDays;
    if (assessmentDays == null) {
      return '-';
    }
    if (assessmentDays === 1) {
      return this.$t('1_day').toString();
    } else {
      return `${assessmentDays} ${this.$t('days')}`;
    }
  }

  /**
   * Gets table row data representing Medical Assessments for the selected recipient.
   * 
   * @param assessments current page state for Medical Assessments form
   * @returns {MedicalAssessmentRow[]} Medical Assessment rows 
   */
  private medicalAssessmentRows(assessments?: AssessmentDecision[]): AssessmentRow[] {
    if (!assessments) {
      return [];
    }
    const rows = assessments.map((assessment: AssessmentDecision) => {
      const recipientCoordinator = this.coordinatorOptions.find((item: GenericCodeValue) => {
        return assessment.recipientCoordinator === item.code;
      });
      const row: AssessmentRow = {
        _id: assessment._id,
        assessmentDate: this.parseDisplayDateUiFromDateTime(assessment.assessmentDate) || '-',
        recipientCoordinator: recipientCoordinator ? recipientCoordinator.value : '-',
        assessmentDecisionValue: this.assessmentDecisionValue(assessment.assessmentDecisionCode) || '-',
        assessmentDecisionReasonValue: this.assessmentDecisionReasonValue(assessment.assessmentDecisionCode, assessment.assessmentDecisionReasonCode) || '-',
      };
      return row;
    });
    return rows;
  }

  /**
   * Gets text representation of an Assessment Decision
   * 
   * @param decisionCode numberic code from lookups representing an Assessment Decision
   * @returns {string|undefined} Assessment Decision as text
   */
  private assessmentDecisionValue(decisionCode?: number): string|undefined {
    const lookupTable = this.assessmentDecisionOptions;
    if (!lookupTable) {
      return undefined;
    }
    const decisionEntry = lookupTable.find((decision: RecipientAssessmentDecision) => {
      return decision.code == decisionCode;
    });
    if (!decisionEntry) {
      return undefined;
    }
    return decisionEntry.value;
  }

  /**
   * Gets text representation of an Assessment Decision Reason
   * 
   * @param decisionCode numeric code representing a Assessment Decision
   * @param reasonCode numeric code representing a Assessment Decision Reason
   * @returns {string|undefined} Assessment Decision Reason as text
   */
  private assessmentDecisionReasonValue(decisionCode?: number, reasonCode?: number): string|undefined {
    const lookupTable = this.assessmentDecisionOptions;
    if (!lookupTable) {
      return undefined;
    }
    const decisionEntry = lookupTable.find((decision: RecipientAssessmentDecision) => {
      return decision.code == decisionCode;
    });
    if (!decisionEntry || !decisionEntry.sub_tables) {
      return undefined;
    }
    const subtable = decisionEntry.sub_tables.recipient_assessment_decision_reason_codes;
    if (!subtable) {
      return undefined;
    }
    const reasonEntry =  subtable.find((reason: RecipientAssessmentDecisionReason) => {
      return reason.code == reasonCode;
    });
    if (!reasonEntry) {
      return undefined;
    }
    return reasonEntry.value;
  }

  /**
   * Builds form edit state based on selected document
   * 
   * @param event select event
   */
  private selectAssessmentDecision(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.editState || !this.assessmentDecisions) {
      return;
    }
    // Find the selected source document
    const found = this.assessmentDecisions.find((decision: AssessmentDecision) => {
      return decision._id && decision._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Store the selection
    this.$store.commit('journeyState/selectAssessmentDecision', found);
    // Build form state based on selected document
    const assessmentDecisionForm: AssessmentPageState = this.buildAssessmentPageState(this.selectedAssessmentDecision);
    Vue.set(this.editState, 'assessment', assessmentDecisionForm);
  }

  /**
   * Resets edit state to prepare for entering a new document
   */
  private createAssessmentDecision(): void {
    // Clear stored selection
    this.$store.commit('journeyState/clearAssessmentDecision');
    // Build empty form state
    const assessmentDecisionForm: AssessmentPageState = this.buildAssessmentPageState();
    Vue.set(this.editState, 'assessment', assessmentDecisionForm);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'factors.assessment_decision_date'                 : 'journey-assessment-date',
    'factors.assessment_decision_coordinator_id'       : 'journey-assessment-coordinator',
    'factors.assessment_decision_code'                 : 'journey-assessment-outcome',
    'factors.assessment_decision_reason_code'          : 'reason_cancelled',
    'factors.assessment_decision_reason_other'         : 'journey-assessment-reason-further-treatment-other',
    'factors.assessment_absolute_contraindication_code': 'journey-assessment-absolute-contraindication',
    'factors.assessment_relative_contraindication_code': 'journey-assessment-relative-contraindication',
  };
}
