import Vue from 'vue';
import { MutationTree } from 'vuex';
import { Coordinator, CoordinatorsState } from '@/store/coordinators/types';

export const mutations: MutationTree<CoordinatorsState> = {
  set(state, coordinators) {
    Vue.set(state, 'filtered', coordinators);
  },
  clear(state, key) {
    Vue.set(state, key, []);
  },
};
