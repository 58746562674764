import store from '@/store';
import { GetterTree } from 'vuex';
import { ObjectId, RootState } from '@/store/types';
import { Hospital } from '@/store/hospitals/types';
import { UsersState, UserAttributes, UserPreferences, UserPreferenceData } from '@/store/users/types';
import { WidgetPermission } from '@/store/dashboard/types';
import { EP } from '@/api-endpoints';
import { isMasked } from '@/utils';
import { faHeadSideHeadphones } from '@fortawesome/pro-regular-svg-icons';

// remove duplicates from array
function uniqBy(a: any, key: any) {
  const seen: any = new Set();
  return a.filter((item: any) => {
      const k = key(item);
      return seen.has(k) ? false : seen.add(k);
  });
}

export const getters: GetterTree<UsersState, RootState> = {
  // Get user object
  getUser(state) {
    return state.user;
  },

  // Get user name
  getUserName(state) {
    const userName = state.user?.name || null;
    if (isMasked(userName)) { return null; }
    return userName;
  },

  /**
   * Returns true if user object populated
   *
   * @returns {boolean}
   */
  isLoggedIn(state, getters) {
    return getters.getUser !== undefined;
  },

  // Get user permissions
  getPermissions(state) {
    return state.user?.permissions;
  },

  // Get user permissions
  getPreferences(state) {
    return state.user && state.user.preferences || null;
  },

  // Get user roles
  getRoles(state) {
    return state.user?.roles;
  },

  // Get all possible user roles e.g. admin page
  getAllRoles(state) {
    return state.roles;
  },

  // Get user responsible physician id
  getUserResponsiblephysicianId(state) {
    return state.user?.responsible_physician_id;
  },

  // Get user coordinator id
  getUserCoordinatorId(state) {
    return state.user?.coordinator_id;
  },
  
  /**
   * Returns preference column options
   *
   * @param columnKey column key
   * @param optionDefaults default options
   * @returns {string[]} return column options from user profile or optionDefaults
   */
  getColumnPreferences(state, getters) {
    return (columnKey: string, optionDefaults: string[]): any => {
      const preferences = getters.getPreferences; // get preferences
      if (preferences[columnKey]) {
        return preferences[columnKey].length > 0 ? preferences[columnKey] : optionDefaults;
      }
      return optionDefaults;
    };
  },

  // Get user organ codes
  getUserOrganCodes(state, getters) {
    const codes: any = getters.getUserHospitalOrganCodes;
    if (codes) {
      const key: string = Object.keys(codes).toString() as string;
      return codes[key];
    } else {
      return codes;
    }
  },

  // get user hospital organ codes
  getUserHospitalOrganCodes(state) {
    return state.user?.hospital_organ_codes || null;
  },

  //get user's hospital organ codes by hospitalId
  getUserHospitalOrganCodesByHospitalId(state, getters) {
    return (hospitalId: string) =>{
      const hospitals = getters.getUserHospitalOrganCodes;
      if (hospitals) {
        return hospitals[hospitalId];
      } else {
        return;
      }
    };
  },

  // get user hospital ids
  getUserHospitalIds(state, getters) {
    const hospitals = getters.getUserHospitalOrganCodes;
    if (hospitals) {
      const result = Object.keys(hospitals); // return only the keys (hospital id's)
      return result;
    } else {
      return [];
    }
  },

  // get user laboratories
  getUserLaboratories(state) {
    return state.user?.laboratories || null;
  },

  /**
   * Get all user laboratory_ids
   * Specific for TGLN Admin role as they will have multiple laboratories
   *
   * @returns {string[]} Array of strings
   */
  getAllUserLaboratoryIds(state) {
    const laboratory_ids = state.user?.laboratory_ids || [];
    const result = laboratory_ids.map((item: ObjectId) => {
      if (item.$oid) return item.$oid;
    });
    return result || [];
  },
  
  // Return route
  getRoute(state) {
    return state.route;
  },

  getUsersTransplantPrograms(state): string[] {
    if (!state.user) return [];
    const userRoles = state.user.roles || [];
    const userTransplantPrograms = userRoles.map((role: string) => {
      // User roles follow a pattern of PROGRAM_ROLENAME_ORGAN.
      // Split the roles at _ to determine which programs.
      const programs = role.split('_') || [];
      return programs[0] ? programs[0].toLowerCase() : '';
    });
    return userTransplantPrograms;
  },

  /**
   * Given a URL, determine whether it is accessible based on the method
   *
   * @returns {(url: string, method?: string) => boolean} true / false if exists
   */
  checkAllowed(state, getters) {
    return (url: string, method = "GET"): boolean => {
      // DIAG: the role hasn't been configured on API in `config/initializers/endpoint_permissions.rb`
      if (!state.user) return false;
      const permissions = getters.getPermissions[method] || [];
      const found = permissions.includes(`/api/v1${url}`);
      return !!found;
    };
  },

  /**
   * Return true if access token available
   *
   */
  hasAccessToken(state) {
    const hasAccessToken = localStorage.getItem('access_token') ? true : false;
    const usingFusionAuth = localStorage.getItem('access_type') == 'fusionauth';
    if (hasAccessToken && !usingFusionAuth) return true;
    if (hasAccessToken && usingFusionAuth) return true;
    return false;
  },

  /**
   * Returns true if allowed to view the oop index/show pages
   *
   */
  canAccessOopRecipients(state, getters, rootState, rootGetters) {
    return rootGetters["features/moduleEnabled"]("oop_recipients") && getters.checkAllowed("/oop_recipients", "GET");
  },

  canPatchAssessments(state, getters) {
    return getters.checkAllowed(EP.recipients.journeys.assessment.update, "PATCH");
  },

  canPatchConsultations(state, getters) {
    return getters.checkAllowed(EP.recipients.journeys.consultation.update, "PATCH");
  },

  canPatchReferrals(state, getters) {
    return getters.checkAllowed(EP.recipients.journeys.referral.update, "PATCH");
  },

  /**
   * Return true if user contains coordinator id
   *
   */
  isCoordinator(state, getters) {
    const coordinator = getters.getUser.coordinator_id ? getters.getUser.coordinator_id.$oid : null;
    return coordinator !== null;
  },

  /**
   * Return true if have access to one/all of the reports api calls
   *
   */
  canMakeReportRequests(state, getters) {
    const permissions = getters?.getUser?.permissions?.POST;
    const contains = new RegExp(/v1\/reports/);
    return contains.test(permissions.flat());
  },

  /**
   * Returns true if can access allocation offers table
   *
   */
   canAccessAllocationOffers(state, getters) {
    return getters.checkAllowed(EP.deceasedDonors.allocations.offers.index, "GET");
  },

  /**
   * Returns true if user can only access donors/active index (transplant coordinator role)
   *
   */
  isTransplantCoordinator(state, getters): boolean {
    const permissions = getters?.getUser?.permissions?.GET;
    const getPermissions = permissions ? permissions.flat() : '';

    // check for access to only /donors/active
    return (getPermissions.includes("/api/v1/donors") == false) && (getPermissions.includes("/api/v1/donors/active") == true);
  },

  /**
   * Returns true if can access full donors index but not allocation offers (surgical recovery coordinator role)
   *
   */
   isSurgicalRecoveryCoordinator(state, getters): boolean {
    return !getters.canAccessAllocationOffers && !getters.isTransplantCoordinator;
  },

  /**
   * Returns true if can only access donors active index and allocation offers index (qa role)
   *
   */
    isQA(state, getters): boolean {
    return getters.canAccessAllocationOffers && getters.isTransplantCoordinator;
  },
  
  /**
   * Returns true if user surgical person, determined by post permissions
   *
   */
  isSurgicalUser(state, getters): boolean {
    // check if physician / surgical user by checking against post permissions
    const permissions = getters?.getUser?.permissions?.POST;
    const postPermissions = permissions ? permissions.flat() : '';

    // sugical users are the onles ones can confirm ExD organ donation acceptances
    return postPermissions.includes('/api/v1/donors/:donor_id/organ_donations/:organ_donation_id/organ_offer/exceptional_distribution/confirmations');
  },

  /**
   * Returns true if user tgln admin, determined by get & post permissions
   *
   */
   isAdmin(state, getters): boolean {
    return getters.checkAllowed("/admin/users/:id", "GET") && getters.checkAllowed("/admin/users", "POST");
  },

  getDonorsUrl(state, getter): string {
    // only used for donors, not living donors
    return getter.isTransplantCoordinator ? 'list-deceased-donors-active' : 'list-deceased-donors';
  },

  /**
   * Return true if csc manager
   *
   */
  isCSCManager(state, getters) {
    // if can access exclusion rules then csc manager
    return getters.canAccessExclusionRules;
  },

  /**
   * Return true if can access exclusion rules on deceased & living donors
   *
   */
  canAccessExclusionRules(state, getters) {
    const deceased = getters.checkAllowed(EP.deceasedDonors.allocations.exclusion_rules, "GET");
    const living = getters.checkAllowed(EP.living_donors.allocations.exclusion_rules, "GET");
    return deceased && living;
  },

  /**
   * Return true if can access user admin pages
   *
   */
  canAccessManageUsers(state, getters) {
    return getters.checkAllowed("/admin/users", "GET");
  },
  /**
   * Returns widget permissions by user's roles
   *
   * @returns {any[]} array of widget permissions by user's roles
   */
   getUserWidgetPermissions(state, getters, rootState, rootGetters): WidgetPermission[] {
    const roles: string[] = getters.getRoles;
    const widgetRolePermissions = rootGetters["dashboard/getWidgetRolePermissions"] || [];
    const widgetPermissions: WidgetPermission[] = [];

    // build widget permissions based on multiple roles
    roles.map((roleName: string) => {
      if (widgetRolePermissions.hasOwnProperty(roleName)) {
        const results = widgetRolePermissions[roleName] || [];
        results.map((item: WidgetPermission) => { 
          widgetPermissions.push(item); 
        });
      }
    });

    // filter duplicates out of array by comparing likeness
    const filteredArray = uniqBy(widgetPermissions, JSON.stringify);
    return filteredArray;
  },
  /**
   * Returns true if widget is required by user's widget permissions 
   *
   * @returns {boolean} true / false
   */
   isWidgetRequired(state, getters) {
    return (widgetName: string): boolean => {
      const widget = getters.getWidgetPermission(widgetName);

      // if none found return false
      if (!widget) return false;

      // otherwise return required result
      return widget.required || false;
    };
  },
  /**
   * Returns true if widget is allowed 
   *
   * @returns {boolean} true / false
   */
   isWidgetAllowed(state, getters) {
    return (widgetName: string): boolean => {
      const widget = getters.getWidgetPermission(widgetName);

      // return true if found and allowed by GET permissions
      return widget && getters.checkAllowed(`/dashboard/${widgetName}`, 'GET');
    };
  },
  /**
   * Returns widget permission attributes
   *
   * @returns {WidgetPermission} widget permission object
   */
   getWidgetPermission(state, getters) {
    return (widgetName: string): boolean => {
      const widgets = getters.getUserWidgetPermissions;
      const widget = widgets.find((item: WidgetPermission) => { return item.widget_name == widgetName; });
      return widget;
    };
   },

  /**
   * verifies access to the now list using user permissions
   *
   * @returns {boolean} true / false
   */
   canAccessNowList(state, getters, rootState, rootGetters): boolean {
    return getters.checkAllowed(EP.nowList.index, "POST");
  },
  /**
   * verifies access to the ctr integration log user permissions
   *
   * @returns {boolean} true / false
   */
  donorCanAccessCtrLog(state, getters, rootState, rootGetters): boolean {
    return getters.checkAllowed(EP.deceasedDonors.ctr_integration_log.index, "GET");
  },
  /**
   * verifies access to the ctr integration log user permissions
   *
   * @returns {boolean} true / false
   */
  recipientCanAccessCtrLog(state, getters, rootState, rootGetters): boolean {
    return getters.checkAllowed(EP.recipients.ctr_integration_log.index, "GET");
  }
};
