
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { RootState, ObjectId, GenericCodeValue } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { DecisionsState, ListDecisions, Decision, ListAttachments, Attachment, ListResponses, Response } from '@/store/decisions/types';
import { CoordinatorOptions } from '@/store/coordinators/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import {ACTIVE_REGION_TRANSPLANT_PROGRAM} from '@/store/hospitals/types';
import ModalSection from '@/components/shared/ModalSection.vue';

export interface AttachmentForm {
  _id?: { $oid: string };
  id?: string;
  fileName?: string;
  fileList?: FileList;
  uploadedFiles?: UploadedFile[];
  description?: string;
  created_at?: string;
  attachment_from?: string;
  selectedFileName?: string;
  selectedId?: string;
}

interface UploadedFile {
  category?: string;
  dateUploaded?: string;
  fileName?: string;
  fileType?: string;
  description?: string;
  uuid?: string;
}

interface AttachmentRow {
  _id?: { $oid: string };
  id?: string;
  dateUploaded?: string;
  fileName?: string;
  fileType?: string;
  description?: string;
  attachment_from?: string;
  deleted?: boolean;
  uuid?: string;
  editRemove?: string;
}

@Component({
  components: {
    TextInput,
    TextAreaInput,
    DateInput,
    SubSection,
    SelectInput,
    SelectOtherInput,
    ModalSection
  }
})
export default class PolicyAttachments extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.attachments) editState!: AttachmentForm;
  @State(state => state.decisions.listAttachments) private attachments!: Attachment[];
  @State(state => state.decisions.selectedDecision) selectedDecision!: Decision;

  // Getters
  @Getter('selectedDecisionId', { namespace: 'decisions' }) selectedDecisionId!: string;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private isTableUpdating = false;
  private taskInProgress = false;

  /**
   * Get a string representation the organ_code
   *
   * @returns {string} organ_code as a string
   */
  get organCode(): string {
    if (this.newJourney) {
      return this.$route.params.organ_code.toString();
    }
    return this.journey.organ_code ? this.journey.organ_code.toString() : '';
  }

  /**
   * Event handle run when clicking on the edit button on a row in the uploaded files table.
   */
  private editFile(row: any) {
    if (row._id) {
      this.$store.dispatch('validations/loadEdit', { view: `recipients/${this.recipientId}/journeys/${this.journeyId}/decisions/${this.selectedDecisionId}/attachments`, action: 'edit', clientId: row._id?.$oid });
    }

    this.initializeAttachmentForm(row);
  }

  public generateDownloadLink(id: string): void {
    const payload: any = {
      recipientId: this.recipient.client_id,
      journeyId: this.journey._id?.$oid,
      decisionId: this.selectedDecisionId,
      attachmentId: id,
    };

    this.$store.dispatch('decisions/downloadAttachment', payload).then((result: any) => {
      const link = document.createElement('a');
      link.href = result.url;
      link.setAttribute('target', '_blank');
      link.setAttribute('download', result.original_filename);
      document.body.appendChild(link);
      link.click();
    }).catch((result: any) => {
      // Show download error
      alert(this.$t('error_generating_download_link').toString());
    });
  }

  // Sets the passed files data to the editState and toggles the delete Modal
  private onDeleteFileClick(row?: any): void {
    if (row) {
      Vue.set(this.editState, 'selectedFileName', row.fileName);
      Vue.set(this.editState, 'selectedId', row.id);
    }

    this.toggleModal('deleteModal');
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  // Handle deleting a file. Called after user accepts delete warning modal dialog popup
  private deleteFile(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveAttachmentSection as unknown as SaveProvider;

    // build payload
    const payload: any = {
      recipientId: this.recipient.client_id,
      journeyId: this.journey._id?.$oid,
      decisionId: this.selectedDecisionId,
      attachmentId: this.editState.selectedId
    };

    if (saveProvider) saveProvider.resetSaveToolbar();

    // make deletion request
    this.$store.dispatch('decisions/deleteAttachment', payload).then((success: SaveResult) => {
      // Clear any errors
      this.$emit('clear');
      // Show success notification
      saveProvider.registerSaveResult(success);
      // Reload index
      this.loadAttachments();
      // Reload parent Policy Exemptions
      this.$emit('reloadIndex');
    }).catch((error: SaveResult) => {
      this.$emit('handleErrors', error);
    });

    this.toggleModal('deleteModal');
  }

  // load validation rules
  public mounted() {
    this.loadAttachments();
  }

  /**
   * Reload list of Policy Attachments.
   *
   */
  public loadAttachments(): void {
    // Skip if no top-level decision is selected
    if (!this.selectedDecisionId) {
      this.initializeAttachmentForm();
      return;
    }

    Promise.all([
      this.$store.dispatch('decisions/indexAttachments', { recipientId: this.recipientId, journeyId: this.journeyId, decisionId: this.selectedDecisionId }),
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/journeys/${this.journeyId}/decisions/${this.selectedDecisionId}/attachments`, action: 'new' }),
    ]).finally(() => {
      this.initializeAttachmentForm();
    }).catch(() => {
      this.initializeAttachmentForm();
    });
  }

  /**
    * Updates form state when Clinical Attachments files are uploaded
    *
    * @listens fileList#changed
    */
  private onAttachmentChanged(event: any) {
    if (!!this.editState && !!event.target) {{
      this.editState.fileList = event.target.files;

      if (this.editState.fileList && this.editState.fileList[0]) {
        Vue.set(this.editState, 'fileName', this.editState.fileList[0].name);
      } else {
        Vue.set(this.editState, 'fileName', '');
      }
    }}
  }

  // Gets table data for policy exemptions.
  get attachmentRows(): AttachmentRow[] {
    if (!this.attachments || this.attachments.length < 0) {
      return [];
    }
    const result: Attachment[] = [];
    this.attachments.forEach((record: Attachment) => {
      const row: AttachmentRow = {
        _id: record._id,
        id: record._id?.$oid,
        deleted: record.deleted,
        dateUploaded: this.parseDisplayDateUiFromDateTime(record.created_at),
        fileName: record.original_filename || '-',
        fileType: record.mime_type,
        description: record.description || '-',
        attachment_from: record.uploaded_by_full_name,
        uuid: record.uuid,
      };
      result.push(row);
    });
    return result;
  }

  get attachmentTableConfig(): TableConfig {
    const tableConfig = [
      { label: this.$t('fileName').toString(), field: 'fileName', sortable: true },
      { label: this.$t('fileType').toString(), field: 'fileType', sortable: true },
      { label: this.$t('description').toString(), field: 'description', sortable: true },
      { label: this.$t('date_added').toString(), field: 'dateUploaded', sortable: true },
      { label: this.$t('added_by').toString(), field: 'attachment_from', sortable: true },
    ];

    if (this.canSave) { tableConfig.push({label: '', field: 'editRemove', sortable: false}); }

    return {
      data: this.attachmentRows,
      columns: tableConfig,
      empty: this.$t('use_form_below_attachment').toString(),
      createButton: this.canSave,
      createText: this.$t('upload_attachment').toString(),
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'dateUploaded', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 3,
        mode: 'records',
        perPageDropdown: [3, 10],
        dropdownAllowAll: true,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }

  // Loads a form edit state based on the lab, or a new state if there is none
  private initializeAttachmentForm(record?: Attachment): void {
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'attachments',
      value: this.buildAttachmentForm(record)
    });
    this.$emit('clear');

    const fileUploader = (this.$refs.fileUploader as any);
    if (fileUploader) fileUploader.value = null;
  }

  /**
   * Returns policy exemption form edit state based on policy exemption document
   *
   * @param record policy exemption document fetched from API
   * @returns {HccResultForm} editable form state
   */
  public buildAttachmentForm(record?: any): any {
    if (!record) {
      return {};
    }

    return {
      _id: record._id,
      id: record._id?.$oid,
      fileName: record.fileName,
      description: record.description,
      created_at: this.parseDateUi(record.created_at),
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {
      'file'              : 'attachment_file',
      'description'       : 'attachment_description'
    };
  }

  // Build an empty new form edit state
  private createAttachment(): void {
    // Build form state
    this.initializeAttachmentForm();
  }

  // Saves current form state for policy exemption
  private saveAttachment(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveAttachmentSection as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'attachment');
    // Generate payload based on current edit state
    const payload: any = {
      recipientId: this.recipient.client_id,
      journeyId: this.journey._id?.$oid,
      decisionId: this.selectedDecisionId,
      attachment: this.extractPatch(),
    };
    // check for attachmentId
    if (this.editState._id && this.editState._id?.$oid) { payload.attachmentId = this.editState._id?.$oid; }

    // Dispatch save action and register the response
    this.$store.dispatch('decisions/saveAttachment', payload).then((success: SaveResult) => {
      // Clear any errors
      this.$emit('clear');
      // Show success notification
      saveProvider.registerSaveResult(success);
      // Reload index
      this.initializeAttachmentForm();
      this.loadAttachments();
      // Reload parent Policy Exemptions
      this.$emit('reloadIndex');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Returns a patch object containing changes for policy exemption document
  private extractPatch(): any {
    if (!this.editState) {
      return {};
    }
    const form = this.editState;

    return {
      fileName: form.fileName,
      fileList: form.fileList,
      description: form.description
    };
  }
}
