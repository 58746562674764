
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { VadDetails, VadInfo, VadProcedure, VadProcedureDetails } from "@/store/organSpecificDetails/types";
import { Recipient } from "@/store/recipients/types";
import { RecipientJourney, RecipientWaitlistAttributes, VADIndicationValue } from '@/store/recipientJourney/types';
import { SaveProvider, SaveResult, TableConfig } from "@/types";
import { calculateNumberOfDays } from "@/utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import { Getter, State } from "vuex-class";
import { VueGoodTable } from "vue-good-table";
import { HeartSpecificPageState } from "./HeartSpecificDetails.vue";
import DateInput from "@/components/shared/DateInput.vue";
import SubSection from "@/components/shared/SubSection.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import CheckboxInput from "@/components/shared/CheckboxInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import { ImplantTypes, Province, VadDevices, VadDiscontinuedReasons, VadIndications, VadInsertSides, VadInsertSideCodes } from "@/store/lookups/types";
import { Hospital, HOSPITAL_OUTSIDE_ONTARIO } from "@/store/hospitals/types";
import { ObjectId } from "@/store/types";
import { NumericCodeValue } from '@/store/types';

export interface VadSpecificForm {
  _id?: { $oid: string };
  implantDate?: string;
  current_hospital_id?: string | undefined;
  implantHospitalCode?: string | null;
  province?: string | null;
  oopHospital?: string;
  indicationDate?: string;
  indicationCode?: number | null;
  insertSideCode?: string | null;
  deviceNameCode?: number | null;
  otherDeviceName?: string | null;
  discontinuationDate?: string | null;
  discontinuationReasonCode?: number | null;
  daysImplanted?: number;
}

interface VadFormRow {
  _id?: { $oid: string };
  implantDate?: string;
  implantHospital?: string;
  indicationDate?: string;
  indication?: string;
}

@Component({
  components: {
    DateInput,
    SubSection,
    NumberInput,
    SelectInput,
    CheckboxInput,
    TextInput,
    VueGoodTable, 
    SelectOtherInput
  }
})
export default class VadSpecificDetails extends mixins(DateUtilsMixin) {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.organSpecificDetails.vadProcedures)
  vadProcedures!: VadProcedure;
  @State(state => state.pageState.currentPage.heartDetails)
  editState!: HeartSpecificPageState;
  @State(state => state.lookups.vad_type) vadImplantTypes!: ImplantTypes[];
  @State(state => state.lookups.province) province!: Province;
  @State(state => state.hospitals.all) private hospitals!: Hospital[];
  @State(state => state.lookups.province) provinceLookup!: Province[];
  @State(state => state.lookups.vad_indication)
  vadIndications!: VadIndications[];
  @State(state => state.lookups.vad_side) vadSides!: VadInsertSides[];
  @State(state => state.lookups.vad_device) vadDevices!: VadDevices[];
  @State(state => state.lookups.vad_discontinued_reason)
  vadDiscontinuedReasons!: VadDiscontinuedReasons;

  //Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter("hospitalOptions", { namespace: "hospitals" }) hospitalOptions!: boolean;  
  @Getter('getHospitalById', { namespace: 'hospitals' }) getHospitalById!: (hospitalCode?: string) => Hospital;
  @Getter("vadHospitalOptions", { namespace: "hospitals" }) vadHospitalOptions!: boolean;  
  @Getter("lookupValue", { namespace: "lookups" }) lookupValue!: ( code: string | undefined, lookupId: string ) => any;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isWaitlisted', { namespace: 'journeyState' }) isWaitlisted!: boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  get waitlistedWithDestinationTherapy() {
    const indicationCode = this.editState?.vadInfo?.indicationCode || null;
    return this.isWaitlisted && indicationCode == VADIndicationValue.Destination_Therapy ? true : false;
  }

  get confirmationText() {
    if (this.waitlistedWithDestinationTherapy) {
      return `${this.$t('saving_vad').toString()}
      
${this.$t('proceed').toString()}`;
    } else {
      return undefined;
    }
  }

  get isEditing(): boolean {
    return this.editState.vadInfo._id ? true : false;
  }

  get getJourneyHospital(): string|undefined {
    return this.journey ? this.journey.transplant_program?.transplant_hospital_id?.$oid : undefined;
  }

  get getCurrentHospitalName(): string|null {
    const hospital = this.getHospitalById(this.editState.vadInfo.current_hospital_id);
    return hospital ? hospital.hospital_name_info.name : null;
  }

  /**
   * Checks if Hospital Name should be shown
   *
   * @returns {boolean} true if the text input area should be shown, false otherwise
   */
  get implantChosenOutsideOntario(): boolean {
    return this.editState.vadInfo.implantHospitalCode === HOSPITAL_OUTSIDE_ONTARIO;
  }

  get vad(): VadProcedure {
    if (this.vadProcedures) {
      return this.vadProcedures;
    } else {
      return {
        left: [],
        right: [],
      };
    }
  }

  /**
   * Get a number of days implanted
   *
   * Calculates the days implanted from the implant date to the current date OR the discontinuation date (if exists)
   *
   * @returns {number|null} Implanted Days or null
   */

  get calculatedDaysImplanted() {
    const implantDate = this.editState.vadInfo.implantDate || null;
    const discontinuationDate = this.editState.vadInfo.discontinuationDate || null;

    // I have an implant date and discontinuation Date
    if (implantDate && discontinuationDate) {
      return calculateNumberOfDays(implantDate, discontinuationDate);
    }
    // I have an implant date only
    if (implantDate && !discontinuationDate) {
      return calculateNumberOfDays(implantDate, this.currentDateUi());
    }
    return null;
  }

  get showOtherDevice(): boolean {
    if (!this.editState.vadInfo.deviceNameCode) {
      return false;
    } else {
      const deviceName = this.lookupValue(
        this.editState.vadInfo.deviceNameCode.toString(),
        "vad_device"
      );
      return deviceName == "OTHER";
    }
    return false;
  }

  get showOtherProvince(): boolean {
    if (!this.editState.vadInfo.implantHospitalCode) {
      return false;
    } else {
      const implantHospital = this.editState.vadInfo.implantHospitalCode;
      const hospital = this.hospitals.find((item: Hospital) => {
        return item._id.$oid == implantHospital.toString();
      });
      return hospital?.location.province_code == "ON" ? false : true;
    }
    return false;
  }

  // returns province dropdown without ontario for the 'other provinces' dropdown
  get otherProvinceDropdown(): Province[] {
    const ontarioIndex = this.provinceLookup.findIndex(
      element => element.code === "ON"
    );
    const provinceWithoutOntario = this.provinceLookup.filter(
      (element, index) => {
        return index === ontarioIndex ? false : element;
      }
    );
    return provinceWithoutOntario;
  }

  /**
   *  Available for edit only for selected row index === 0 === max(date) from collection
   */
  get canEdit(): boolean {
    if (this.newJourney || this.journey.completed) {
      return false;
    }

    const { _id, insertSideCode } = this.editState.vadInfo;
    if (_id && insertSideCode && this.vad.left && this.vad.right) {
      const entries = insertSideCode === VadInsertSideCodes.Left ? this.vad.left : this.vad.right;
      return this.isLastEntry(_id, entries);
    }

    return true;
  }

  public mounted(): void {
    this.loadVadInformation();   
  }

  /**
   * Begin fetching the recipient's Vad Information
   */
  public loadVadInformation(): void {
    const recipientId = this.recipient.client_id;
    this.$store.dispatch("organSpecificDetails/loadVadInfo", recipientId).then(() => {
      this.initializeVadForm();
    });
  }

  /**
   * Gets a base configuration for the Vad Information Table 
   *
   * @returns {any} Partial Vad Table configuration
   */
  get baseTableConfig(): any {
    return {
      columns: [
        { label: this.$t('implant_date').toString(), field: "implantDate", width: "20%" },
        {
          label: this.$t('implant_hospital').toString(),
          field: "implantHospital",
          width: "20%"
        },
        {
          label: this.$t('indication_date').toString(),
          field: "indicationDate",
          width: "20%"
        },
        { label: this.$t('indication').toString(), field: "indication", width: "20%" }
      ],
      empty: this.$t('use_form_below').toString(),
      createText: this.$t('create_vad_info').toString(),
      createButton: this.canEdit,
      // Disable unused sorting feature, because Vue Good Table has sorting enabled by default
      sortOptions: {
        enabled: false
      }
    };
  }

  /**
   * Gets configuration for the Vad Information table Left-side
   *
   * @returns {TableConfig} Vad Table configuration
   */
  get vadImplantLeftTableConfig(): TableConfig {
    return {
      data: this.vadInfoRows(this.vad && this.vad.left ? this.vad.left : []), 
      ...this.baseTableConfig
    };
  }

  /**
   * Gets configuration for the Vad Information table Right-side
   *
   * @returns {TableConfig} Vad Table configuration
   */
  get vadImplantRightTableConfig(): TableConfig {
    return {
      data: this.vadInfoRows(this.vad && this.vad.right ? this.vad.right : []), 
      ...this.baseTableConfig
    };
  }

  /**
   * Gets table row data for the Vad Information table
   *
   * @returns {VadFormRow[]} Vad table rows
   */
  vadInfoRows(vadRows: VadProcedureDetails[]): VadFormRow[] {
    if (!vadRows || vadRows.length < 0 || this.newJourney) {
      return [];
    }
    const result: VadFormRow[] = [];

    vadRows.forEach((vad: VadProcedureDetails) => {
      let implantHospitalId = vad.implant_hospital_id
        ? vad.implant_hospital_id.$oid
        : undefined;
      const vadDetails = vad.details ? vad.details[0] : undefined;

      const implantHospital = this.hospitals.find((item: Hospital) => {
        return implantHospitalId === item._id.$oid;
      });

      const vadIndication = this.vadIndications.find((item: VadIndications) => {
        return vadDetails?.indication_code === item.code;
      });

      const row: VadFormRow = {
        _id: vad._id,
        implantDate: this.parseDisplayDateUi(vad.implant_date),
        implantHospital: implantHospital?.hospital_name_info.abbreviation,
        indicationDate: vadDetails?.indication_date
          ? this.parseDisplayDateUi(vadDetails?.indication_date)
          : undefined,
        indication: vadIndication?.value
      };
      result.push(row);
    });
    return result;
  }

  /**
   * Loads the selected row into editState.vadInfo
   *
   * @listens hsd-vad#table-row-click
   * @param event selected row from the table
   */
  public selectVadImplantInfoLeft(event: { row: VadProcedureDetails }): void {
    const selectedId =
      event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (this.vad.left && this.vad.left.length > 0) {
      const vadInfo = this.vad.left as VadProcedureDetails[];
      const foundVad: VadProcedureDetails | undefined = vadInfo.find(
        (item: VadProcedureDetails) => {
          return item._id && item._id.$oid === selectedId;
        }
      );
      if (foundVad) {
        this.initializeVadForm(foundVad);
      }
    }
  }

  /**
   * Loads the selected row into editState.vadInfo
   *
   * @listens hsd-vad#table-row-click
   * @param event selected row from the table
   */
  public selectVadImplantInfoRight(event: { row: VadProcedureDetails }): void {
    const selectedId =
      event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (this.vad.right && this.vad.right.length > 0) {
      const vadInfo = this.vad.right as VadProcedureDetails[];
      const foundVad: VadProcedureDetails | undefined = vadInfo.find(
        (item: VadProcedureDetails) => {
          return item._id && item._id.$oid === selectedId;
        }
      );
      if (foundVad) {
        this.initializeVadForm(foundVad);
      }
    }
  }

  /**
   * Loads a form edit state based on a vad, or a new state if there is none
   *
   * @param vad: Vad Info entry fetched from selected row, or undefined
   */
  public initializeVadForm(vad?: VadProcedureDetails): void {
    this.$store.commit("pageState/set", {
      pageKey: "heartDetails",
      componentKey: "vadInfo",
      value: this.buildVadInfoPageState(vad)
    });

    this.$emit("clear", "vadInfo");
    this.resetSaveToolbar();
  }

  public getImplantHospitalId(hospitalId?: string|null): any {
    return hospitalId === HOSPITAL_OUTSIDE_ONTARIO ? null : hospitalId;
  }

  public selectedImplantHospital(hospitalId: string|null) {
    if (hospitalId !== HOSPITAL_OUTSIDE_ONTARIO) {
      Vue.set(this.editState.vadInfo, 'province', null);
      Vue.set(this.editState.vadInfo, 'oopHospital', null);
    }
  }

  /**
   * Converts a selected VadInfo entry into VadForm
   *
   * @param VadInfo Vad Information
   * @returns {VadForm} Vad Form entry
   */
  public buildVadInfoPageState(vad?: VadProcedureDetails): VadSpecificForm {
    const journey_hospital_id = this.getJourneyHospital;
    if (!vad) {
      return {
        current_hospital_id: journey_hospital_id,
        implantDate: this.currentDateUi(),
      };
    }

    let implantHospitalCode = null;
    if (vad.oop_hospital) {
      implantHospitalCode = HOSPITAL_OUTSIDE_ONTARIO;
    } else {
      implantHospitalCode = vad.implant_hospital_id
        ? vad.implant_hospital_id.$oid
        : undefined;
    }

    const vadDetails = vad.details ? vad.details[0] : undefined;
    const current_hospital_id = vad.current_hospital_id ? vad.current_hospital_id?.$oid : journey_hospital_id;

    const result: VadSpecificForm = {
      _id: vad._id,
      implantDate: vad.implant_date
        ? this.parseDateUi(vad.implant_date)
        : this.currentDateUi(),
      current_hospital_id: current_hospital_id,
      implantHospitalCode: implantHospitalCode,
      province: vad.oop_province,
      oopHospital: vad.oop_hospital,
      indicationDate: vadDetails?.indication_date
        ? this.parseDateUi(vadDetails?.indication_date)
        : undefined,
      indicationCode: vadDetails?.indication_code,
      insertSideCode: vad.insert_side,
      deviceNameCode: vad?.device_code,
      otherDeviceName: vad?.device_other,
      discontinuationDate: vad.discontinued_date
        ? this.parseDateUi(vad.discontinued_date)
        : undefined,
      discontinuationReasonCode: vad.discontinued_reason_code
    };
    return result;
  }

  /**
   * Deselects any selected Vad Information, and clears the entry for
   * editState.heartDetails.vadInfo
   *
   * @listens hsd-vad#table-create-row
   */
  public createVadImplantInfo(): void {
    this.initializeVadForm();
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = (this.$refs.saveVadImplantInfo as unknown) as SaveProvider;
    // Reset the save provider's save toolbar
    if (gci) {
      gci.resetSaveToolbar();
    }
  }

  /**
   * Attempt to save the Hemodynamic Information.
   *
   * Prepares an update payload for Hemodynamic Information,
   * dispatches a save action, and registers the save result.
   */
  private saveVadImplantInfo(): void {
    // Generate payload based on current edit state
    const vadPayload = {
      id: this.editState.vadInfo!._id,
      recipientId: this.recipient.client_id,
      vadInfo: this.extractVadInfoPatch()
    };

    // if waitlisted with destination therapy, register a reload needed
    const reloadNeeded = this.waitlistedWithDestinationTherapy;

    // Refer to the save provider that handles this form area
    const saveProvider = (this.$refs.saveVadImplantInfo as unknown) as SaveProvider;

    // Report to parent that saving has began
    this.$emit("saving", "vadImplantInfo");

    // Dispatch save action and register the response
    this.$store
      .dispatch("organSpecificDetails/saveVadInfo", vadPayload)
      .then((success: SaveResult) => {
        // If successful, reload all of the recipient's hemodynamic info and show success notification
        // If was on a waitlist the system should trigger an action to remove them from the waitlist without ui interaction
        this.loadVadInformation();
        this.createVadImplantInfo();
        saveProvider.registerSaveResult(success);

        // reload recipient & journey if needed
        if (reloadNeeded) { this.reload(); }
      })
      .catch((error: SaveResult) => {
        // Emit event to handle errors
        this.$emit("handleErrors", error);
        // Show error notification
        saveProvider.registerSaveResult(error);
      });
  }

  /**
   * Reload recipient and heart journey, as well as any new Waitlist Decisions.
   *
   * This is needed because add a VAD record with Desination Therapy can affect the Journey's Waitlist Factors.
   */
  private reload(): void {
    // Reload recipient and journey for latest Waitlist Factors
    this.$store.dispatch('recipients/get', this.recipientId).then(() => {
      this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
        // Reload all waitlist decisions for the recipient's journey
        const waitlistDecisionsConfig = {
          journeyId: this.journeyId,
          recipientId: this.recipientId,
        };
        this.$store.dispatch('journeyState/loadWaitlistDecisions', waitlistDecisionsConfig);
      });
    });
  }

  /**
   * Gets the entry for editSate.heartDetails.vadInfo
   *
   * Converts the entry for Vad Information from editState
   * into a payload ready for the API.
   *
   * @returns {HemodynamicInfo} object containing any field changes
   */
  private extractVadInfoPatch(): VadInfo {
    if (!this.editState || !this.editState.vadInfo) {
      return {};
    } else {
      const vadInfo = this.editState.vadInfo;
      let details: VadDetails[] = [];

      const vadDetails: VadDetails = {
        indication_date: this.sanitizeDateApi(vadInfo.indicationDate) || null,
        indication_code: vadInfo.indicationCode || null,
      };

      const implantHospitalCode = vadInfo.implantHospitalCode || null;
      const implantHospitalId = this.getImplantHospitalId(implantHospitalCode);
      const oop_implant = vadInfo.implantHospitalCode == HOSPITAL_OUTSIDE_ONTARIO ? true : false;

      details.push(vadDetails);

      const result = {
        implant_date: this.sanitizeDateApi(vadInfo.implantDate) || null,
        implant_hospital_id: implantHospitalId || undefined,
        oop_implant: oop_implant,
        oop_province: vadInfo.province || null,
        oop_hospital: vadInfo.oopHospital || null,
        insert_side: vadInfo.insertSideCode || null,
        discontinued_date: vadInfo.discontinuationDate ? this.sanitizeDateApi(vadInfo.discontinuationDate) : null,
        discontinued_reason_code: vadInfo.discontinuationReasonCode || null,
        device_code: vadInfo.deviceNameCode || null,
        device_other: vadInfo.otherDeviceName || null,
        current_hospital_id : this.journey.transplant_program?.transplant_hospital_id?.$oid,
        details: details
      };
      return result;
    }
  }

  // Indication Date only required if in Ontario i.e. OOP VAD don't need Indication Date
  // TODO: TECH_DEBT these should probably be driven by new_validations / edit_validations
  get isIndicationDateRequired(): boolean {
    const isOutOfProvince = this.showOtherProvince || false;
    return !isOutOfProvince;
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result: { [key: string]: string } = {
      "implant_date"               : "implant-date",
      "insert_side"                : "insert-side",
      "implant_hospital_id"        : "implant-hospital",
      "oop_province"               : "province",
      "oop_hospital"               : "oop-hospital",
      "details[0].indication_date" : "indication-date",
      "details[0].indication_code" : "indication",
      "device_code"                : "device-name",
      "device_other"               : "other-device-name",
      "discontinued_date"          : "discontinuationDate",
      "discontinued_reason_code"   : "discontinuationReasonCode",
      "current_hospital_id"        : "current-hospital"
    };
    return result;
  }
}
