
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import StickySummary from "@/components/shared/StickySummary.vue";
import { Recipient } from "@/store/recipients/types";
import { RecipientJourney } from "@/store/recipientJourney/types";
import { LivingDonor, LivingDonorAllocationSummary, LivingDonorJourney } from "@/store/livingDonors/types";
import { ClassObject } from '@/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { isMasked, mergeClasses } from '@/utils';
import { Hospital } from '@/store/hospitals/types';

interface livingDonorStickySummaryForm {
  date_of_birth?: string|null|undefined;
  client_id?: number;
  created_at?: string;
  oats_id?: number;
  tgln_id?: string;
  donor_name?: string;
  sex?: string;
  age?: string|number;
  abo?: string|null;
  abo_sub_type?: string|null;
  donor_type?: string;
  height?: number;
  weight?: number
  region?: string;
  serology?: string;
  transplant_program?: string|null;
}

@Component({
  components: {
    OrganIcon,
    StickySummary
  }
})
export default class LivingDonorStickySummary extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.livingDonorStickySummary) editState!: livingDonorStickySummaryForm;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  //Getters
  @Getter('clientId', { namespace: 'livingDonors' }) private livingDonorId!: string;
  @Getter("livingDonorDisplayName", { namespace: "livingDonors" }) private livingDonorDisplayName!: string;
  @Getter('getDonorAge', { namespace: 'livingDonors' }) getDonorAge!: number|undefined;
  @Getter('selectedLivingDonorConsentedOrganList', { namespace: 'livingDonors'}) private selectedLivingDonorConsentedOrganList!: LivingDonorAllocationSummary[];
  @Getter('selectedLivingDonorJourney', { namespace: 'livingDonors' }) selectedLivingDonorJourney!: LivingDonorJourney|null;
  @Getter('getOntarioHospitalById', { namespace: 'hospitals' }) private getOntarioHospitalById!: (hospitalId?: string|null) => Hospital|null;

  @Watch('livingDonor', { immediate: true })
  private onLivingDonorChanged(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.$emit('loaded', 'livingDonorStickySummary');
  }

  // Private methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'livingDonorStickySummary',
      value: this.extractLivingDonorStickySummaryForm(this.livingDonor)
    });
  }

  // Format date for display
  public formatDateTime(dateValue: string): string|undefined {
    if (!isNaN(Date.parse(dateValue))) {
      return this.parseFormattedDateTimeUi(dateValue);
    } else {
      return dateValue;
    }
  }

  public extractLivingDonorStickySummaryForm(donor?: LivingDonor): livingDonorStickySummaryForm {
    // Return empty object if there is no data document
    if (!donor) {
      return {};
    }
    // Sanitize nested objects, so their parameters can easily be read as 'undefined' instead of raising type errors
    const patientProfile = donor.patient_profile || {};
    const referral = donor.referral || {};
    const blood = donor.blood || {};
    const donorMeasurements = donor.measurements || [];
    const birth = donor.patient_profile?.birth || {};

    const addresses = patientProfile.addresses || {};
    // Most recent measurement will be first in the array
    const measurement = donorMeasurements.length > 0 ? donorMeasurements[0] : {};

    const hospital = this.selectedLivingDonorJourney?.transplant_program ? this.getOntarioHospitalById(this.selectedLivingDonorJourney?.transplant_program?.transplant_hospital_id?.$oid) : undefined;
    const hospitalName = hospital?.hospital_name_info.abbreviation;

    const birthDate = !isMasked(birth.date) ? this.parseDisplayDateUi(birth.date) : birth.date;

    // Return parameters extracted from data document based on structure of form state interface
    return {
      date_of_birth: birthDate,
      client_id: donor.client_id,
      tgln_id: donor.living_donor_id ? `${donor.living_donor_id}` : '-',
      created_at: this.parseDateUiFromDateTime(donor.created_at),
      oats_id: donor.client_id,
      donor_name: patientProfile.first_name && patientProfile.last_name,
      sex: patientProfile.sex || undefined,
      age: isMasked(birth.date) ? '*******' : this.getDonorAge,
      abo: blood.sub_type ? blood.sub_type : blood.type,
      height: measurement.height,
      weight: measurement.weight,
      transplant_program: hospitalName || undefined
    };
  }

  //Getter - Selected organ shown as active
  get listItemClass(){
    return (consentedOrgan: any): ClassObject => {
      const option = this.$route.params.option;
      const organCode = this.$route.params.organ_code;
      const consentedOrganRoute = consentedOrgan.route.params.option;
      const consentedOrganCode = consentedOrgan.organ_code;
      let isActive : any;

      if(consentedOrgan.route.params.option)
      {
        isActive = consentedOrgan.display_name.toLowerCase().includes(option) && organCode == consentedOrganCode.toString();
      } else {
        isActive = organCode == consentedOrganCode.toString();
      }
      //Define dynamic class based on the selected organ
      const conditionalClass = {
        'active' : isActive
      };
      return conditionalClass;
    };
  }
}
