
import PageTop from '@/components/shared/PageTop.vue';
import { Getter, State } from 'vuex-class';
import SelectInput from '@/components/shared/SelectInput.vue';
import TransplantInProgressReport from '@/views/reports/TransplantInProgressReport.vue';
import TransplantActivityReport from '@/views/reports/TransplantActivityReport.vue';
import ChangeStatusReport from '@/views/reports/ChangeStatusReport.vue';
import CrossmatchReport from '@/views/reports/CrossmatchReport.vue';
import DialysisReport from '@/views/reports/DialysisReport.vue';
import WaitingListReport from '@/views/reports/WaitingListReport.vue';
import OrganYieldUtilizationReport from '@/views/reports/OrganYieldUtilizationReport.vue';
import RecipientDataAuditReport from '@/views/reports/RecipientDataAuditReport.vue';
import AllocationReport from '@/views/reports/AllocationReport.vue';
import { ReportState, WaitingListReportState, allocationReports } from '@/store/reports/types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { waitingListReports, recipientDataAuditReports } from '@/store/reports/types';
import NationalOrganWaitlistModal from '@/components/shared/NationalOrganWaitlistModal.vue';
import { SaveResult } from '@/types';
import { User } from '@/store/users/types';
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";
import { OrganCodeValue } from '@/store/lookups/types';

@Component({
  components: {
    PageTop,
    SelectInput,
    AllocationReport,
    TransplantInProgressReport,
    TransplantActivityReport,
    WaitingListReport,
    OrganYieldUtilizationReport,
    RecipientDataAuditReport,
    ChangeStatusReport,
    CrossmatchReport,
    DialysisReport,
    NationalOrganWaitlistModal,
    FullPageListLayout
  }
})

export default class Reports extends mixins(ValidationUtilsMixin) {
  @State(state => state.reports) editState!: ReportState;
  @State(state => state.users.user) user!: User;

  // Getters
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('canAccessNowList', { namespace: 'users' }) private canAccessNowList!: boolean;

  get allowedReports(): any[]{
    const reports = [
       // descoped: { code: 'crossmatch_report', value: 'CrossMatch Worksheet Report' },  // Story 8.2.4
      //{ code: 'dialysis_report', value: 'Dialysis Report' }, // Story 8.2.6
      //{ code: 'change_status_report', value: 'Change of Status Report' },
      //{ code: 'organ_yield_report', value: 'Organ Yield and Utilization Report' }, // Story 8.2.13
      { code: 'now_list', value: this.$t('national_organ_waitlist').toString() }, // Bug 8547
      //{ code: recipientDataAuditReports.Detail, value: 'Recipient Data Audit Detail' }, // Story 8.2.15
      //{ code: recipientDataAuditReports.Index, value: 'Recipient Data Audit Index' }, // Story 8.2.16
      { code: 'transplant_activity_report', value: this.$t('transplant_activity_report').toString() }, // Story 8.2.18
      { code: 'transplant_in_progress_report', value: this.$t('transplant_in_progress').toString() },  // Story 8.2.19
      { code: waitingListReports.WaitingListReport, value: this.$t('waiting_list_report').toString() }, // Story 8.2.20
      { code: waitingListReports.WaitingListKidneyPancreas, value: this.$t('waiting_list_report_kidney_pak_kp').toString() }, // Story 8.2.21
      { code: waitingListReports.WaitingListLungHeart, value: this.$t('waiting_list_report_lung_heart_lung').toString() }, // Story 8.2.22
      { code: waitingListReports.WaitingListPancreasIslets, value: this.$t('waiting_list_report_pancreatic_islets').toString() }, // Story 8.2.23
      { code: waitingListReports.WaitingListHeartClusters, value: this.$t('waiting_list_report_heart_heart_clusters').toString() }, // Story 8.2.24
      { code: waitingListReports.WaitingListLiverClusters, value: this.$t('waiting_list_report_liver_liver_clusters').toString() }, // Story 8.2.25
      { code: waitingListReports.WaitingListPancreasWhole, value: this.$t('waiting_list_report_pancreas_whole').toString() } // Story 8.2.21
    ];

    // filter reports by api access
    const filteredReports = reports.filter((report: any) => {
      if (report.code == 'now_list') {
        if (this.canAccessNowList) return report;
      } else {
        if (this.checkAllowed(`/reports/${report.code}`, 'POST')) return report;
      }
    });

    return filteredReports.concat(this.allowedAllocationReports);
  }

  get allowedAllocationReports(): any[] {
    const filterBy: { [key: string]: any } = this.user.hospital_organ_codes || {};
    const allocationReportCodes = [
      { code: allocationReports.AllocationHeart, organ_code: OrganCodeValue.Heart, value: this.$t('allocation_report_heart').toString() }, // Bug 11677
      { code: allocationReports.AllocationKidneyPancreas, organ_code: OrganCodeValue.Kidney, value: this.$t('allocation_report_kidney_pancreas').toString() }, // Bug 11677
      { code: allocationReports.AllocationLiver, organ_code: OrganCodeValue.Liver, value: this.$t('allocation_report_liver').toString() }, // Bug 11677
      { code: allocationReports.AllocationLung, organ_code: OrganCodeValue.Lung, value: this.$t('allocation_report_lung').toString() }, // Bug 11677
      { code: allocationReports.AllocationPancreasWhole, organ_code: OrganCodeValue.PancreasWhole, value: this.$t('allocation_report_pancreas_whole').toString() },
      { code: allocationReports.AllocationPancreasIslets, organ_code: OrganCodeValue.PancreasIslets, value: this.$t('allocation_report_pancreas_islet').toString() }, // Bug 11677
      { code: allocationReports.AllocationSmallBowel, organ_code: OrganCodeValue.SmallBowel, value: this.$t('allocation_report_small_bowel').toString() } // Bug 11677
    ];

    if (!this.checkAllowed(`/reports/allocation_report`, 'POST')) return [];

    const userOrganCodes = Object.keys(filterBy).map((hospital: string) => filterBy[hospital]).flat();

    return userOrganCodes.length > 0 ? allocationReportCodes.filter((report) => userOrganCodes.includes(report.organ_code)) : allocationReportCodes;
  }

  private lookupsToLoad: string[] = [
    'blood_type',
  ];

  private isLookupsLoaded = false;
  private isHospitalsLoaded = false;

  public mounted(): void {
    this.loadLookups();
  }

  private loadLookups(): void {
    const lookupsLoaded: string[] = [];
    const numberOfLookupsToLoad = this.lookupsToLoad.length;
    this.lookupsToLoad.forEach((lookupId: string) => {
      this.$store.dispatch('lookups/queueLookup', {lookup: lookupId}).then(() => {
        lookupsLoaded.push(lookupId);
        if(lookupsLoaded.length === numberOfLookupsToLoad) {
          this.isLookupsLoaded = true;
          this.checkIfLoadingComplete();
        }
      });
    });
  }

  private checkIfLoadingComplete(): void {
    const isLoaded = this.isLookupsLoaded && this.isHospitalsLoaded;
    if (isLoaded) {
      this.initializePageState();
    }
  }

  private initializePageState(): void {
    // Initialize filter form state
    this.$store.dispatch('reports/new');
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).reset();
  }

  public selectReport(report: string){
    this.$store.commit('reports/setCurrentReport', report);
    if (report === 'now_list') {
      this.openNowModal();
      return;
    }

    if (report !== 'transplant_in_progress_report') {
      // clear waiting list report fields when changing reports
      const waitingListReport = this.$refs.waitingListReport as WaitingListReport;
      if (waitingListReport) waitingListReport.clearParametersOnReportChange();
    }
  }

  // Open Now Modal
  private openNowModal(): void {
    const nationalOrganWaitlistModal = this.$refs.nationalOrganWaitlistModal as NationalOrganWaitlistModal;
    nationalOrganWaitlistModal.initialize();
  }

  /**
   * Check if selected report from dropdown is Transplant In Progress
   *
   * @returns {boolean} returns true if report selected is Transplant in Progress
   */
  get isTransplantInProgressReport(): boolean {
    return this.editState?.currentReport === "transplant_in_progress_report";
  }

  /**
   * Check if selected report from dropdown is Recipient Data Audit Index
   *
   * @returns {boolean} returns true if report selected is Recipient Data Audit Index
  */
   get isAllocationReport(): boolean {
    const listOfAllocationReports : string[] = Object.values(allocationReports);
    return listOfAllocationReports.includes(this.editState.currentReport!);
  }

  /**
   * Check if selected report from dropdown is Transplant Activity
   *
   * @returns {boolean} returns true if report selected is Transplant Activity
   */
  get isTransplantActivityReport(): boolean {
    return this.editState?.currentReport === "transplant_activity_report";
  }

  /**
   * Check if selected report from dropdown is Change of Status Report
   *
   * @returns {boolean} returns true if report selected is Change of status Report
   */
  get isChangeStatusReport(): boolean {
    return this.editState?.currentReport === "change_status_report";
  }

  /**
   * Check if selected report from dropdown is Crossmatch Report
   *
   * @returns {boolean} returns true if report selected is Crossmatch Report
   */
  get isCrossMatchReport(): boolean {
    return this.editState?.currentReport === "crossmatch_report";
  }

  /**
   * Check if selected report from dropdown is Crossmatch Report
   *
   * @returns {boolean} returns true if report selected is Crossmatch Report
   */
  get isDialysisReport(): boolean {
    return this.editState?.currentReport === "dialysis_report";
  }

  /**
   * Check if selected report from dropdown is a type of Waiting List Report
   *
   * @returns {boolean} returns true if report selected is a Waiting List Report
   */
  get isAWaitingListReport(): boolean {
    const listOfWaitingListReports : string[] = Object.values(waitingListReports);
    return listOfWaitingListReports.includes(this.editState.currentReport!);
  }

  /**
   * Check if selected report from dropdown is Organ Yield Utilization
   *
   * @returns {boolean} returns true if report selected is Organ Yield Utilization
   */
  get isOrganYieldUtilizationReport(): boolean {
    return this.editState?.currentReport === "organ_yield_report";
  }

  /**
   * Check if selected report from dropdown is Recipient Data Audit Index
   *
   * @returns {boolean} returns true if report selected is Recipient Data Audit Index
  */
  get isARecipientDataAuditReport(): boolean {
    const listOfRecipientDataAuditReports : string[] = Object.values(recipientDataAuditReports);
    return listOfRecipientDataAuditReports.includes(this.editState.currentReport!);
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {

    const organYieldUtilization =  this.$refs.organYieldUtilization as OrganYieldUtilizationReport;
    const recipientDataAudit = this.$refs.recipientDataAudit as RecipientDataAuditReport;
    const transplantActivity = this.$refs.transplantActivity as TransplantActivityReport;

    let idLookup: IdLookup = {};

    // the components are not always visible so they will cause errors if we don't add conditionals
    if (organYieldUtilization) {
      idLookup = { ...organYieldUtilization.idLookup };
    }
    if (recipientDataAudit) {
      idLookup = { ...recipientDataAudit.idLookup };
    }
    if (transplantActivity) {
      idLookup = { ...transplantActivity.idLookup };
    }
    
    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
  }


}
