
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { EP } from '@/api-endpoints';
import { Getter, State, } from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import { Hospital } from '@/store/hospitals/types';
import { Recipient } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { CoordinatorOptions } from '@/store/coordinators/types';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { TableConfig, SaveableSection, SaveProvider, SaveResult } from '@/types';
import { PostTransplantSectionPageState } from '@/components/organs/shared/_PostTransplantSection.vue';
import { RecipientJourney, PostTransplantTransferPayload, RecipientJourneyTransferStatus, TransferType } from '@/store/recipientJourney/types';

export interface PostTransplantTransferState {
  date?: string|null;
  hospital?: string|null;
  coordinator?: string|null;
}

interface PostTransplantTransferHistoryRow {
  date: string;
  fromHospital: string;
  toHospital: string;
}

@Component({
  components: {
    DateInput,
    SubSection,
    SelectInput,
  }
})

export default class PostTransplantTransferSection extends mixins(DateUtilsMixin) implements SaveableSection {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.postTransplantSection) editState!: PostTransplantSectionPageState;

  @Getter('isClustered', { namespace: 'journeyState', }) isClustered!: boolean;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string|undefined;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('checkAllowed', { namespace: 'users' }) checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('currentFollowUpHospitalId', { namespace: 'journeyState'}) currentFollowUpHospitalId!: string|null;
  @Getter('currentFollowUpCoordinatorId', { namespace: 'journeyState'}) currentFollowUpCoordinatorId!: string|null;
  @Getter('getHospitalById', { namespace: 'hospitals' }) getHospitalById!: (hospitalId?: string|null) => Hospital|null;
  @Getter('mostRecentFollowUpTransferDate', { namespace: 'journeyState'}) mostRecentFollowUpTransferDate!: string|null;
  @Getter('followUpCoordinatorOptions', { namespace: 'coordinators' }) followUpCoordinatorOptions!: CoordinatorOptions[];
  @Getter('regionTransplantOptionsByOrgan', { namespace: 'hospitals' }) regionTransplantOptionsByOrgan!: (organCode?: number) => GenericCodeValue[];
  @Getter('transferStatusesByTypes', { namespace: 'journeyState', }) transferStatusesByTypes!: (transferTypes: string[]) => RecipientJourneyTransferStatus[];

  @Prop({ default: false }) newJourney!: boolean
  @Prop({ default: false }) canSave!: boolean;

  // Private state attributes

  private isLoading = true;
  private isSubSectionLoading = true;
  private isLoadingCoordinators = false;

  // Getters

  // History rows based on selected journey transplant program and transfer statuses
  get postTransplantTransferHistoryRows(): PostTransplantTransferHistoryRow[] {
    if (!this.journey || !this.journey.transfer_statuses) return [];

    // Each row shows 'from' and 'to' hospital, so we will need to cache previous hospital
    // Note: previous hospital for first transfer is the transplant program hospital itself
    let previousHospitalId = this.journey?.transplant_program?.transplant_hospital_id?.$oid || null;

    // Filter transfer status objects to only include the Post-Transplant Follow-Up transfers
    // NOTE: here is where we are removing Active Transfer entries from the Transfer History (see ATU-8619)
    const postTransplantTransferStatuses = this.transferStatusesByTypes([TransferType.PostTransplant]);

    const rows = postTransplantTransferStatuses.map((transferStatus: RecipientJourneyTransferStatus): PostTransplantTransferHistoryRow => {
      // Parse date
      const date = this.parseDisplayDateUi(transferStatus.status_changed_date) || '-';
      // Fetch the next 'to hospital' ID
      const nextHospitalId = transferStatus?.hospital_id?.$oid || null;
      // Get hospital names
      const fromHospital = this.getHospitalById(previousHospitalId)?.hospital_name_info?.name || '-';
      const toHospital = this.getHospitalById(nextHospitalId)?.hospital_name_info?.name || '-';
      // Cache hospital ID as the 'from hospital' for the following iteration, if any
      previousHospitalId = nextHospitalId;
      return {
        date,
        fromHospital,
        toHospital,
      };
    });

    return rows;
  }

  get postTransplantTransferTableConfig(): TableConfig {
    return {
      data: this.postTransplantTransferHistoryRows,
      columns: [
        { label: this.$t('transfer_date').toString(), field: 'date',         width: '30%' },
        { label: this.$t('from_hospital').toString(), field: 'fromHospital', width: '35%' },
        { label: this.$t('to_hospital').toString(),   field: 'toHospital',   width: '35%' }
      ],
      empty: this.$t('use_form_below').toString(),
    };
  }

  get showSaveButton(): boolean {
    return true;
  }

  get isSectionDisabled(): boolean {
    return this.isLoading || !this.isTransferFollowUpAuthorized;
  }

  // True only if user is permitted to save
  get isTransferFollowUpAuthorized(): boolean {
    return this.checkAllowed(EP.recipients.journeys.transferStatuses.postTransplant.singleFollowUpTransfer, 'PUT');
  }

  get confirmationText(): string|undefined {
    let key: string;
    if (this.isClustered) {
      // secondary confirmation prompt for cluster
      key = 'post_transplant_transfer_confirmation_cluster';
    } else {
      // secondary confirmation prompt for single journey
      key = 'post_transplant_transfer_confirmation';
    }
    return this.$t(key).toString();
  }

  // Transplant programs that have access to the same {organ} as the selected journey
  get followUpHospitalOptions(): GenericCodeValue[] {
    if (!this.journey || !this.journey.organ_code) return [];

    return this.regionTransplantOptionsByOrgan(this.journey.organ_code);
  }

  // Private event handlers

  private loaded(): void {
    this.isSubSectionLoading = false;

    this.checkIfLoadingComplete();
  }

  private mounted(): void {
    this.loadFollowUpCoordinators(this.currentFollowUpHospitalId);
  }

  /**
   * Load coordinators associated with the hospital. If no hospital, this will clear the options.
   *
   * Note: here we request vue-x store the coordinators in separately from the usual 'filtered'
   * state, because this list is set based on a different hospital than what is entered at the
   * top of the journey page. In other words, because this Recipient Coordinator dropdown is
   * expected to have different data we store it in 'followUpCoordinators' state instead.
   */
  private loadFollowUpCoordinators(hospitalId: string|null): void {
    // Clear out any previously loaded data
    this.$store.commit('coordinators/clear', 'followUpCoordinators');

    // Check if hospital specified
    if (!hospitalId) {
      // Don't need to load any coordinators
      this.isLoadingCoordinators = false;
      this.checkIfLoadingComplete();
    } else {
      // Start loading coordinators
      this.isLoadingCoordinators = true;
      this.$store.dispatch('coordinators/load', { hospitalId, key: 'followUpCoordinators' }).then(() => {
        // Done loading coordinators
        this.isLoadingCoordinators = false;
        this.checkIfLoadingComplete();
      }).catch((err) => {
        console.warn(err);
      });
    }
  }

  private checkIfLoadingComplete(): void {
    if (!this.isLoading) return;
    this.isLoading = this.isSubSectionLoading || this.isLoadingCoordinators;
  }
  
  // Initialize Form
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'postTransplantSection',
      componentKey: 'transferFollowUp',
      value: this.buildPageState(),
    });
    this.isLoading = true;
    this.loadFollowUpCoordinators(this.currentFollowUpHospitalId);
  }

  // Process changes to Follow-Up Hospital dropdown in edit state
  private onFollowUpHospitalChanged(newValue: string|null): void {
    if (!this.editState || !this.editState.transferFollowUp) return;

    // Populate Transfer Date with today's date
    Vue.set(this.editState.transferFollowUp, 'date', this.currentDateUi());

    // Clear selection from Recipient Coordinator dropdown
    Vue.set(this.editState.transferFollowUp, 'coordinator', null);
  
    // Reload coordinator options
    this.loadFollowUpCoordinators(newValue);
  }

  // Public loading-related methods

  public buildPageState(): PostTransplantTransferState {
    // Defaults
    const result: PostTransplantTransferState = {
      date: null,
      hospital: null,
      coordinator: null,
    };

    // Fetch current hospital and coordinator responsible for this journey's Follow-Ups
    const date = this.mostRecentFollowUpTransferDate;
    const hospital = this.currentFollowUpHospitalId;
    const coordinator = this.currentFollowUpCoordinatorId;
    Object.assign(result, {
      date,
      hospital,
      coordinator,
    });

    return result;
  }

  // Public saving-related methods

  // Save this sub-section
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.postTransplantFollowUpHospital as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'postTransplantFollowUpHospital');
    // Generate payload based on current edit state
    const payload = {
      journeyId: this.journeyId,
      recipientId: this.recipientId,
      transferStatus: this.extractPatch(),
      prefix: 'transferFollowUp',
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/createFollowUpTransfer', payload).then((success: SaveResult) => {
      // Reload recipient and journey
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          // Re-initialize form state based on response from API
          this.initializeForm();
          // Report to parent that saving has completed so it can clear validations and reload if necessary
          this.$emit('saved', 'postTransplantFollowUpHospital');
        });
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle field-level validation errors
      this.$emit('handleErrors', error);
      // Show error messages directly in the save toolbar
      saveProvider.registerSaveResult(error);
    });
  }

  // Build request payload for API based on form edit state in UI
  public extractPatch(): PostTransplantTransferPayload {
    // Defaults
    const result: PostTransplantTransferPayload = {
      hospital_id: null,
      coordinator_id: null,
    };

    // Check if form state is available
    const form = this.editState?.transferFollowUp;
    if (!form) return result;

    // Build payload based on form state
    Object.assign(result, {
      hospital_id:    form.hospital ? { $oid: form.hospital } : null,
      coordinator_id: form.coordinator ? { $oid: form.coordinator } : null,
    });
    return result;
  }

  // Reset save toolbar
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.postTransplantFollowUpHospital as unknown as SaveProvider;
    if (saveProvider) saveProvider.resetSaveToolbar();
  }

  // Map from API validation error keys to UI form element ID
  public idLookup(): IdLookup {
    return {
      'transferFollowUp.hospital_id'    : 'transfer-follow-up-hospital',
      'transferFollowUp.coordinator_id' : 'transfer-follow-up-coordinator',
    };
  }
}

