
import { Component, Vue, Prop } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { SaveProvider, SaveResult, DismissableNotification } from '@/types';
import LoadingSection from '@/components/shared/LoadingSection.vue';

@Component({
  components: {
    SaveToolbar,
    LoadingSection
  }
})
export default class CardSection extends Vue implements SaveProvider {
  @Prop({ required: true }) sectionId!: string;
  @Prop({ default: false }) isAccordion!: boolean;
  @Prop({ default: () => { return []; } }) lookupsToLoad!: string[];
  @Prop({ default: false }) showLoading!: boolean;
  @Prop({ default: false }) saveButton!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: 'Save' }) saveButtonText!: string;
  @Prop({ default: () => { return []; } }) laboratoriesToLoad!: string[];
  @Prop({ default: () => { return []; } }) hospitalsToLoad!: string[];
  @Prop({ required: false }) confirmation?: string;

  // Set this to true to show the action button slot. Using a dedicated boolean flag lets the style classes adapt.
  @Prop({ default: false }) showActions!: boolean;

  public show = true;
  public isLoading = false;
  queue: any;

  public mounted(): void {
    if (this.isAccordion === true) {
      this.show = false;
    }
    // Load any lookups if we have them
    if (this.lookupsToLoad.length > 0 || this.laboratoriesToLoad.length > 0 || this.hospitalsToLoad.length > 0) {
      this.getLookups(this.lookupsToLoad, this.laboratoriesToLoad, this.hospitalsToLoad);
    } else {
      this.$emit('loaded');
    }
  }

  getLookups(lookupsToLoad: string[], laboratoriesToLoad: string[], hospitalsToLoad: string[]) {
    const itemsLoaded: string[] = [];
    const numberOfItemsToLoad = lookupsToLoad.length + laboratoriesToLoad.length + hospitalsToLoad.length;
    this.isLoading = true;

    // Set a timeout id so we can clear if loading finishes before the timeout
    const timeoutId = setTimeout(() => {
      this.isLoading = false;
      // Emit a loaded event anyways so we don't block any interaction
      this.$emit('loaded');
    }, 3333);

    lookupsToLoad.forEach(item => {
      this.$store.dispatch('lookups/queueLookup', { lookup: item});
    });
    // let queue: any = Promise.resolve(true);

    // function add(operation: any, queue: any) {
    //   return new Promise((resolve, reject) => {
    //     queue = queue
    //       // .then(operation)
    //       .then(() => { console.log(operation);})
    //       .then(resolve)
    //       .catch(reject);
    //   });
    // }
    
    // function add(operation: any): any {
    //   return new Promise((resolve, reject) => {
    //     queue
    //       .then(() => {
    //         _this.$store.dispatch('lookups/queueLookup', {lookup: item});
    //       })
    //       .then(() => {
    //         resolve;
    //       })
    //       .catch(reject);
    //   });
    // }

    // lookupsToLoad.forEach(item => {
    //   // load them
    //   this.$store.dispatch('lookups/queueLookup', {lookup: item}).then(() => {
    //     itemsLoaded.push(item);
    //     if(itemsLoaded.length === numberOfItemsToLoad) {
    //       this.isLoading = false;
    //       this.$emit('loaded');
    //       clearTimeout(timeoutId);
    //     }
    //   });
    // });

    laboratoriesToLoad.forEach(item => {
      // load them
      this.$store.dispatch('laboratories/load', item).then(() => {
        itemsLoaded.push(item);
        if(itemsLoaded.length === numberOfItemsToLoad) {
          this.isLoading = false;
          this.$emit('loaded');
          clearTimeout(timeoutId);
        }
      });
    });

    if (hospitalsToLoad.length > 0) {
      this.$store.dispatch('hospitals/load').then(() => {
        itemsLoaded.push('hospitals');
        if(itemsLoaded.length === numberOfItemsToLoad) {
          this.isLoading = false;
          this.$emit('loaded');
          clearTimeout(timeoutId);
        }
      });
    }

  }


  toggle() {
    this.show = !this.show;
  }

  beforeEnter(el: HTMLElement) {
    el.style.height = '0';
  }
  enter(el: HTMLElement) {
    el.style.height = el.scrollHeight + 'px';
  }
  beforeLeave(el: HTMLElement) {
    el.style.height = el.scrollHeight + 'px';
  }
  leave(el: HTMLElement) {
    el.style.height = '0';
  }

  // Generate a unique reference for the save toolbar using the card section ID
  private saveToolbarRef(): string {
    return `save-${this.sectionId}`;
  }

  // Use dynamic reference to refer to the save toolbar associated with this specific card section
  private saveToolbar(): SaveToolbar {
    return this.$refs[this.saveToolbarRef()] as SaveToolbar;
  }

  // Handle saving triggered by local save button
  public performSave(): void {
    // Show confirmation prompt if necessary
    if (this.confirmation !== undefined) {
      const confirmed = confirm(this.confirmation);
      if (!confirmed) {
        // Cancel save if not confirmed
        return;
      }
    }
    // Show saving notification
    this.saveToolbar().startSaving();
    // Report save event to parent
    this.$emit('save');
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    // Show appropriate saving notification
    this.saveToolbar().stopSaving(result);
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    const saveToolbar = this.saveToolbar();
    if(saveToolbar) this.saveToolbar().reset();
  }
}
