import axios from 'axios';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { prefixErrors, buildErrorResult } from '@/utils';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { Recipient, RecipientsState, SignificantEventDecision } from '@/store/recipients/types';

export const actions: ActionTree<RecipientsState, RootState> = {
  new({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('set', getters.new);
      resolve();
    });
  },
  getList({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.index, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedRecipients: Recipient[] = response.data.recipients;
        commit('setList', sanitizedRecipients);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject(error);
      });
    });
  },
  getListOop({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const indexUrl = EP.recipients.outOfProvince.index;
      const url = APIRoute(indexUrl, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedRecipients: Recipient[] = response.data.oop_recipients;
        commit('setList', sanitizedRecipients);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject(error);
      });
    });
  },
  get({ commit, getters }, clientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.show, [[':id', clientId]]);
      axios.get(url).then((response: any) => {
        const sanitizedRecipient: Recipient = response.data.recipient;
        commit('set', sanitizedRecipient);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  getOop({ commit, getters }, clientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.outOfProvince.show, [[':id', clientId]]);
      axios.get(url).then((response: any) => {
        const sanitizedRecipient: Recipient = response.data.oop_recipient;
        commit('set', sanitizedRecipient);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  getCtrLogs({ commit, getters }, { clientId }): Promise<void> {
    return new Promise<void>((resolve) => {
      const url = APIRoute(EP.recipients.ctr_integration_log.index, [[':id', clientId]]);
      axios.get(url).then((response: any) => {
        // Check if our request was successful 
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Data is nested here
          const responseData = response.data.ctr_integration_log || [];
          // Commit the logs to our state
          commit('setCtrLogs', responseData);
        } else {
          // Clear CTR Logs if we were successful  
          commit('clearCtrLogs');
        }
        resolve();
      });
    });
  },
  loadMeasurements({ commit, getters }, clientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.measurements.index, [[':recipientId', clientId]]);
      axios.get(url).then((response: any) => {
        commit('setMeasurements', response.data.measurements);
        resolve(response.data.measurements);
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  saveDialysis({ commit, getters }, { id, recipientId, dialysis }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { procedure_dialysis: dialysis } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.dialysis_procedures.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.dialysis_procedures.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          const error = response.data.errors;
          // Handle server-side validation errors
          reject(buildErrorResult(response.data.errors));
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadDialysis({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.dialysis_procedures.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        commit('setDialysisProcedures', response.data.dialysis_procedures);
        resolve(response);
      }).catch((error) => {
        reject();
      });
    });
  },
  saveMeasurement({ commit, getters }, { id, recipientId, measurement }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { measurements: measurement } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.measurements.update, [[':recipientId', recipientId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.measurements.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Prefix errors with measurements
          const validationErrors = prefixErrors(response.data.errors, 'measurements');
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveRecipientPatch({ commit, getters },  recipientPatch ): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      if (recipientPatch) {
        // Prepare information to send
        const ep = APIRoute(EP.recipients.update, [[':id', getters.show.client_id]]);
        const payload = { recipient: recipientPatch };
        // Send asynchronously
        axios.patch(ep, payload).then((response: any) => {
          // Check if the update was successful
          const isSuccessful = response.data && !response.data.errors;
          if (isSuccessful) {
            // Handle successful response
            resolve({ success: true, responseData: response.data });
          } else if (response.data && response.data.errors) {
            //is string if Warning kind of Potential duplicate profile
            if(typeof response.data.errors === 'string' && response.data.errors.includes("recipient_duplicate_profile")){
                reject({
                  success: false,
                  errorMessages: ["validation.messages.duplicate_profile_error"],
                  warningMessage: response.data.errors,
                  warning: true
                });
            }else {
              // Generate error for virology_labs test time field if date failed
              if ('virology_labs.test_date' in response.data.errors) {
                response.data.errors['virology_labs.test_time'] = response.data.errors['virology_labs.test_date'];
              }
              // Generate error for virology_labs sample time field if date failed
              if ('virology_labs.sample_date' in response.data.errors) {
                response.data.errors['virology_labs.sample_time'] = response.data.errors['virology_labs.sample_date'];
              }
              // Handle server-side validation errors
              reject({
                success: false,
                errorMessages: ['Cannot save: see error messages above'],
                validationErrors: response.data.errors
              });
            }
          }
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        });
      } else {
        reject();
      }
    });
  },
  saveRecipient({ commit, getters }, { recipientId, recipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      if (recipient) {
        let method: any;
        let ep: string;
        const payload = { recipient: recipient };
        if (recipientId) {
          method = axios.patch;
          ep = APIRoute(EP.recipients.update, [[':id', recipientId]]);
        } else {
          method = axios.post;
          ep = APIRoute(EP.recipients.create);
        }
        method(ep, payload).then((response: any) => {
          const isSuccessful = response.data && !response.data.errors;
          if (isSuccessful) {
            // Handle successful response
            resolve({ success: true, responseData: response.data });
          } else if (response.data && response.data.errors) {
            //is string if Warning kind of Potential duplicate profile
            if(typeof response.data.errors === 'string' && response.data.errors.includes("recipient_duplicate_profile") ){
              reject({
                success: false,
                errorMessages: ["validation.messages.duplicate_profile_error"],
                warningMessage: response.data.errors,
                warning: true
              });
            }else {
              // Generate error for virology_labs test time field if date failed
              if ('virology_labs.test_date' in response.data.errors) {
                response.data.errors['virology_labs.test_time'] = response.data.errors['virology_labs.test_date'];
              }
              // Generate error for virology_labs sample time field if date failed
              if ('virology_labs.sample_date' in response.data.errors) {
                response.data.errors['virology_labs.sample_time'] = response.data.errors['virology_labs.sample_date'];
              }
              // Handle server-side validation errors
              reject({
                success: false,
                errorMessages: ['Cannot save: see error messages above'],
                validationErrors: response.data.errors
              });
            }
          }
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        });
      } else {
        reject();
      }
    });
  },
  uploadFile({ commit }, { recipientId, fileList, fileName }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any = axios.post;
      const ep = APIRoute(EP.recipients.attachments.create, [[':recipientId', recipientId]]);

      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      // Avoid appending in `undefined` values for keys that we shouldnt be sending because
      // the data is empty
      const payload: FormData = new FormData();

      if (!!fileName) {
        payload.append('attachment[original_filename]', fileName);
      }
      if (fileList && fileList[0]) {
        payload.append('attachment[file]', fileList[0]);
      }
      method = axios.post;

      // Send asynchronously
      method(ep, payload, headers).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject({ success: false, errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  addUploadedFile({ commit }, { originalId, attachment }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('addUploadedFileToList', { originalId, attachment });
      resolve();
    });
  },
  downloadFile({  }, { recipientId, id }): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const ep = APIRoute(EP.recipients.attachments.show, [[':recipientId', recipientId], [':id', id]]);
      axios.get(ep).then((response: any) => {
        resolve(response.data.attachment.url);
      }).catch((error: any) => {
        // Handle generic errors
        reject(error);
      });
    });
  },
  deleteFile( { commit }, { recipientId, id } ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Deletion is handled by updating with soft-delete fields
      const payload = {
        deleted: true,
        deleted_date: new Date().toISOString()
      };

      const ep = APIRoute(EP.recipients.attachments.update, [[':recipientId', recipientId], [':id', id]]);
      axios.patch(ep, payload).then((_response: any) => {
        commit('removeUploadedFileFromList', { id });
        resolve();
      }).catch((response: any) => {
        console.log('error trying to delete file');
        console.log(response);
        reject();
      });
    });
  },
  loadDecisionEvents({ commit }, recipientId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Clear previous response
      commit('clearDecisionEvents', { recipientId });
      // Prepare request
      const url = APIRoute(EP.recipients.significantEventDecisions.index, [[':recipientId', recipientId]]);
      // Dispatch request
      axios.get(url).then((response: any) => {
        // Process successful response
        const events: SignificantEventDecision[] = response.data.significant_event_decisions;
        commit('setDecisionEvents', { recipientId, events });
        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
        reject();
      });
    });
  },
  loadOutOfProvinceJourneys({ commit, getters }, clientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.outOfProvinceJourneys.index, [[':recipientId', clientId]]);
      axios.get(url).then((response: any) => {
        commit('setOutOfProvinceJourneys', response.data.oop_journeys);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  saveOutOfProvinceJourney({ commit, getters }, { recipientId, journeyId, journey }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      let method: any;
      let url: string;
      if (journeyId) {
        method = axios.patch;
        url = APIRoute(EP.recipients.outOfProvinceJourneys.update, [[':recipientId', recipientId], [':id', journeyId.$oid]]);
      } else {
        method = axios.post;
        url = APIRoute(EP.recipients.outOfProvinceJourneys.create, [[':recipientId', recipientId]]);
      }
      method(url, { journey }).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveRecipientDeath({ getters }, { recipientId, death }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      const ep = APIRoute(EP.recipients.death.update, [[':recipientId', recipientId]]);
      const payload = { death: death } ;

      // Send asynchronously
      axios.patch(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveDiagnosticsHla({ getters }, { recipientId, diagnosticsHla }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      const ep = APIRoute(EP.recipients.diagnosticsHla.update, [[':recipientId', recipientId]]);
      const payload = { diagnostics_hla: diagnosticsHla } ;

      // Send asynchronously
      axios.patch(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
};
