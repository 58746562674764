
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { aggregateSaveResults, isMasked } from '@/utils';
import { BloodType, SubBloodType, BloodTypeValue, CauseOfDeathDonor, RhIndicator } from '@/store/lookups/types';
import { DeceasedDonor, DeceasedDonorMeasurement, DeceasedDonorOrganRetrievalSurgery } from '@/store/deceasedDonors/types';
import { HospitalOption } from '@/store/hospitals/types';
import { ReferralInformationForm } from './ReferralInformation.vue';

interface ClinicalInformationPageState {
  abo?: string|null;
  aboSubType?: string|null;
  rh?: string|null;
  height?: number;
  weight?: number;
  bmi?: number;
  totalLungCapacity?: number;
  causeOfDeathCode?: number;
  withdrawalOfLifeSupportDate?: string|null;
  withdrawalOfLifeSupportTime?: string;
  declarationHospital?: string|null;
  declarationDate?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    TimeInput,
    SubSection,
    NumberInput,
    CardSection,
    SelectInput,
  }
})
export default class ClinicalInformation extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.clinicalInformation) editState!: ClinicalInformationPageState;
  @State(state => state.pageState.currentPage.referralInformation) referralInformationState!: ReferralInformationForm;
  @State(state => state.lookups.cause_of_death_donor) private causeOfDeathDonorLookup!: CauseOfDeathDonor[];

  @Getter('clientId', { namespace: 'deceasedDonors' }) clientId!: string|undefined;
  @Getter('hospitalOptionsOOP', { namespace: 'hospitals' }) hospitalOptionsOOP!: HospitalOption[];
  @Getter('hospitalOptionsOntarioAll', { namespace: 'hospitals' }) hospitalOptionsOntarioAll!: HospitalOption[];
  @Getter('bloodTypesForDonor', { namespace: 'lookups' }) private bloodTypesForDonor!: BloodType[];
  @Getter('rhIndicatorsForDonor', { namespace: 'lookups' }) private rhIndicatorsForDonor!: RhIndicator[];
  @Getter('bloodSubTypes', { namespace: 'lookups' }) bloodSubTypes!: (bloodType: string) => SubBloodType[];

  // Properties
  @Prop({ default: false }) newDonor!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['blood_type', 'sub_blood_type', 'cause_of_death_donor', 'rh_indicator'];

  /**
   * Gets boolean representation if we have A or AB blood type selected
   *
   * @returns {boolean} true if A or AB selected
   */
  get showAboSubType(): boolean {
    if (!this.editState || !this.editState.abo) {
      return false;
    }
    return this.editState.abo == BloodTypeValue.A || this.editState.abo == BloodTypeValue.AB;
  }

  // Gets a filtered list of sub blood type values by passing the blood type
  get bloodSubTypeOptions(): SubBloodType[] {
    if (this.editState.abo == null) {
      return [];
    }
    return this.bloodSubTypes(this.editState.abo);
  }

  // clear ABO sub type when changing ABO
  private clearBloodSubType(): void {
    Vue.set(this.editState, 'aboSubType', undefined);
  }

  get referralHospitalsList(): HospitalOption[] {
    if(this.referralInformationState && this.referralInformationState.out_of_province_donation) {
      return this.hospitalOptionsOOP;
    } else {
      return this.hospitalOptionsOntarioAll;
    }
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  public mounted(): void {
    this.initializeForm();
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens clinicalInformation#loaded
   * @emits loaded
   */
  public loaded(): void {
    if (!this.newDonor) {
      this.calculateMeasurement();
      this.calculateTlc();
    }
    this.$emit('loaded', 'clinicalInformation');
  }

  /**
   * Populates the Clinical Information form state with data from the selected Deceased Donor.
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'clinicalInformation',
      value: this.buildClinicalInformationPageState(this.deceasedDonor)
    });
  }

  // Updates BMI, Height and Weight from API triggered on change
  // from height / weight fields, or selecting a measurement
  public calculateMeasurement(from?: string) {
    // Check if height or weight was masked before making the API call for BMI
    const height = isMasked(this.editState?.height?.toString()) ? undefined : this.editState?.height;
    const weight = isMasked(this.editState?.weight?.toString()) ? undefined : this.editState?.weight;
    // If either field is masked skip this request
    if (!height || !weight) return;
    const params = {
      height: height,
      height_unit: 'cm',
      weight: weight,
      weight_unit: 'kg',
    };
    this.$store.dispatch('deceasedDonors/loadBmiHeightWeight', params).then((bmiResponse) => {
      this.setBMI(bmiResponse);
    }).catch((error) => {
      console.warn(error.description);
    });
  }

  // Calculates TLC if TLC doesn't exist
  // from age / gender / weight fields
  public calculateTlc() {
    const donorMeasurements = this.deceasedDonor.measurements || [];
    const donorMeasurement = donorMeasurements.length > 0 ? donorMeasurements[donorMeasurements.length-1] : {};
    if (donorMeasurement.total_lung_capacity == null) {
      const params = {
        age: this.deceasedDonor.patient_profile?.age,
        gender: this.deceasedDonor.patient_profile?.sex || this.deceasedDonor.patient_profile?.gender,
        height: donorMeasurement.height
      };
      this.$store.dispatch('tools/calculateTlc', params).then((tlcResponse) => {
        this.setTLC(tlcResponse);
      }).catch((error) => {
        console.warn(error.description);
      });
    }
  }

  // Variable used in the warning params of the TLC text input box
  private SHOW_TLC_WARNING = false;

  // Used to determine which warning message should be displayed for the TLC text input
  private tlcWarningMessage(): string {
    if (!this.deceasedDonor || !this.deceasedDonor.patient_profile) return '';

    let reasons = [];
    if (this.deceasedDonor.patient_profile?.sex == 'U') {
      reasons.push(this.$t('sex_unknown'));
    }
    if (!this.deceasedDonor.measurements![0].height) {
      reasons.push(this.$t('height_missing'));
    }
    if (!this.deceasedDonor.patient_profile?.age) {
      reasons.push(this.$t('age_missing'));
    }
    return this.$t('cannot_calculate_tlc') + reasons.join(', ');
  }
    
  // Used to determine if the TLC text input should be left blank or return a TLC value of message: 'Not Enough Information'
  // Also used to determine whether to show a warning message or not
  private tlcNotEnoughInformation(): string {
    if (!this.deceasedDonor || !this.deceasedDonor.patient_profile) return '';

    if (this.deceasedDonor.patient_profile!.age! < 18 && (this.deceasedDonor.measurements![0].height! > 181 || this.deceasedDonor.measurements![0].height! < 100)) {
      this.SHOW_TLC_WARNING = false;
      return '';
    } else if (this.deceasedDonor.patient_profile?.gender == 'U' || !!this.deceasedDonor.measurements![0].height || !this.deceasedDonor.patient_profile?.age) {
      this.SHOW_TLC_WARNING = true;
      return this.$t('not_enough_information').toString();
    }
    this.SHOW_TLC_WARNING = false;
    return '';
  }

  /**
   * Generates Referral Information form state based on a Deceased Donor document
   *
   * @param deceasedDonor Deceased Donor document provided by API
   * @returns {ClinicalInformationPageState} page state for Clinical Information
   */
  public buildClinicalInformationPageState(deceasedDonor?: DeceasedDonor): ClinicalInformationPageState {
    if (!deceasedDonor) { return {}; }

    // Cautiously get data from source donor and measurement documents
    const donor = deceasedDonor || {};
    const donorMeasurements = donor.measurements || [];
    const measurement = donorMeasurements.length > 0 ? donorMeasurements[donorMeasurements.length-1] : {};
    const total_lung_capacity = measurement.total_lung_capacity;
    const death = deceasedDonor.death || {};
    const deceasedDonorOrganRetrievalSurgery = deceasedDonor.organ_retrieval_surgery || {};
    const deceasedDonorBlood = deceasedDonor.blood || {};

    let hospitalId = death.hospital_id_declared ? death.hospital_id_declared.$oid : undefined;

    // Return parameters extracted from data document based on structure of form state interface
    return {
      abo: deceasedDonorBlood.type,
      aboSubType: deceasedDonorBlood.sub_type,
      rh: deceasedDonorBlood.rh,
      height: isMasked(measurement.height?.toString()) ? undefined : measurement.height,
      weight: isMasked(measurement.weight?.toString()) ? undefined : measurement.weight,
      totalLungCapacity: total_lung_capacity ? this.toFixed(total_lung_capacity, 2) : undefined,
      causeOfDeathCode: death.cause_of_death_code,
      withdrawalOfLifeSupportDate: this.parseDateUiFromDateTime(deceasedDonorOrganRetrievalSurgery.life_support_withdrawal_date),
      withdrawalOfLifeSupportTime: this.parseTimeUiFromDateTime(deceasedDonorOrganRetrievalSurgery.life_support_withdrawal_date),
      declarationHospital: hospitalId,
      declarationDate: this.parseDateUiFromDateTime(death.first_declare_date)
    };
  }

  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveClinicalInformation as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Donor, dispatches a save action, handle errors and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveClinicalInformation as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'clinicalInformation');
    // Generate payload based on current edit state
    const donorPatch = this.extractPatch();
    // Dispatch save action and register the response
    this.$store.dispatch('deceasedDonors/saveDonor', { clientId: this.clientId, donor: donorPatch }).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      this.$store.commit('deceasedDonors/set', success.responseData.donor);
      // Clear form for entry of a new measurement
      this.initializeForm();
      this.calculateMeasurement();
      // Show success notification
      saveProvider.registerSaveResult(success);
      // Request donor page reload data that might be affected by this form changing
      this.$emit('reload');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState) {
      return {};
    }
    return this.extractClinicalInformationPatch(this.editState);
  }

  // Private methods

  /**
   * Gets form edit state changes as a Deceased Donor patch
   *
   * Changes to Deceased Donor clinical information
   *
   * @param clinicalInformation form edit state containing changes
   * @returns {DeceasedDonor} patch object containing field changes
   */
  private extractClinicalInformationPatch(clinicalInformation: ClinicalInformationPageState): DeceasedDonor {
    // Measurement
    const donorMeasurements = this.deceasedDonor.measurements || [];
    const measurement = donorMeasurements.length > 0 ? donorMeasurements[donorMeasurements.length-1] : {};
    // If aboSubType is not A or AB nuke this value
    const aboSubType = this.showAboSubType ? clinicalInformation.aboSubType : null;

    const result = {
      death: {
        cause_of_death_code: clinicalInformation.causeOfDeathCode,
        hospital_id_declared: (clinicalInformation.declarationHospital ? { $oid: clinicalInformation.declarationHospital } : null),
        first_declare_date: this.sanitizeDateApi(clinicalInformation.declarationDate)
      },
      blood: {
        type: clinicalInformation.abo || null,
        sub_type: aboSubType || null,
        rh: clinicalInformation.rh || null
      },
      organ_retrieval_surgery: {
        life_support_withdrawal_date: this.sanitizeDateTimeApi(clinicalInformation.withdrawalOfLifeSupportDate, clinicalInformation.withdrawalOfLifeSupportTime)
      },
      measurements: [{
        _id: measurement._id,
        weight: clinicalInformation.weight,
        height: clinicalInformation.height,
        total_lung_capacity: clinicalInformation.totalLungCapacity
      }]
    };
    return result;
  }

  // Returns a number to a fixed number of sidigits if it's a number
  private toFixed(number: any, sigdits: number): any {
    if (Number.isFinite(number)) {
      return number.toFixed(sigdits);
    } else {
      return number;
    }
  }

  /**
   * Update bmi value with reponse from API
   *
   * @param bmiResponse bmiResponse from API
   */
  private setBMI(bmiResponse: any): void {
    const bmi = bmiResponse.bmi;
    this.$store.commit('pageState/set', {
      pageKey: 'clinicalInformation',
      componentKey: 'bmi',
      value: bmi
    });
  }

  /**
   * Update tlc value with reponse from API
   *
   * @param tlcResponse tlcResponse from API
   */
  private setTLC(tlcResponse: any): void {
    const tlc = tlcResponse.tlc;
    this.$store.commit('pageState/set', {
      pageKey: 'clinicalInformation',
      componentKey: 'totalLungCapacity',
      value: tlc
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'blood.type'                                          : 'clinicalInformation-abo',
    'blood.sub_type'                                      : 'clinicalInformation-abo_sub_type',
    'blood.rh'                                            : 'clinicalInformation-rh',
    'measurements.height'                                 : 'clinicalInformation-height',
    'measurements.weight'                                 : 'clinicalInformation-weight',
    'measurements[0].height'                              : 'clinicalInformation-height',
    'measurements[0].weight'                              : 'clinicalInformation-weight',
    'bmi'                                                 : 'clinicalInformation-bmi',
    'total_lung_capacity'                                 : 'clinicalInformation-total_lung_capacity',
    'death.cause_of_death_code'                           : 'clinicalInformation-cause_of_death_code',
    'organ_retrieval_surgery.life_support_withdrawal_date': 'clinicalInformation-withdrawal_of_life_support_date',
    'organ_retrieval_surgery.life_support_withdrawal_time': 'clinicalInformation-withdrawal_of_life_support_time',
    'death.hospital_id_declared'                          : 'clinicalInformation-declaration_hospital',
    'death.first_declare_date'                            : 'clinicalInformation-declaration_date'
  }
}
